import React, { useState, useMemo, memo, useCallback } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import Card from '../ui/Card';
import Button from '../ui/Button';
import Dropdown from '../ui/Dropdown';

const FiltersBar = memo(({ 
  selectedLocation, 
  onLocationChange, 
  sortConfig, 
  onSortChange,
  locations,
  t 
}) => {
  const [isLocationOpen, setIsLocationOpen] = useState(false);

  const locationDropdownItems = useMemo(() => 
    Object.entries(locations).map(([key, location]) => ({
      icon: location.icon,
      title: t(`locations.${key}`),
      onClick: () => {
        onLocationChange(key);
        setIsLocationOpen(false);
      }
    })), [locations, onLocationChange, t]
  );

  const handleSort = (key) => {
    onSortChange(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <div className="w-full px-4 mt-20 mb-8 relative z-[100] flex justify-center">
      <div className="w-full max-w-[400px] sm:max-w-none flex flex-col gap-4 sm:flex-row sm:flex-wrap sm:justify-center">
        <div className="w-full flex flex-col gap-4 bg-white dark:bg-white/10 backdrop-blur-lg rounded-xl border border-gray-200 dark:border-white/10 shadow-lg sm:flex-row sm:w-auto relative">
          {/* Mobile Layout */}
          <div className="block sm:hidden w-full">
            {/* Location Dropdown - Mobile */}
            <div className="w-full relative z-[101] p-3 border-b border-gray-200 dark:border-white/10">
              <Dropdown
                id="location-selector"
                buttonText={
                  <div className="flex items-center gap-2 w-full justify-center">
                    <i className="bx bx-map-pin text-xl text-blue-500 dark:text-blue-400" />
                    <span className="text-gray-700 dark:text-gray-300 text-base">
                      {t(`locations.${selectedLocation}`)}
                    </span>
                  </div>
                }
                items={locationDropdownItems}
                isOpen={isLocationOpen}
                setIsOpen={setIsLocationOpen}
                className="hover:bg-gray-50 dark:hover:bg-white/5 rounded-lg transition-colors relative w-full text-center"
                menuClassName="z-[102] relative"
              />
            </div>
            {/* Sort Buttons - Mobile */}
            <div className="flex flex-row w-full justify-center p-3">
              <Button
                variant="ghost"
                onClick={() => handleSort('price')}
                className={`
                  flex items-center justify-center gap-2 rounded-lg px-6
                  hover:bg-gray-50 dark:hover:bg-white/5 transition-colors
                  ${sortConfig.key === 'price' 
                    ? 'text-blue-500 dark:text-blue-400' 
                    : 'text-gray-600 dark:text-gray-300'}
                `}
              >
                <i className="bx bx-dollar text-xl" />
                <i className={`bx ${
                  sortConfig.key === 'price' 
                    ? sortConfig.direction === 'asc' 
                      ? 'bx-sort-up' 
                      : 'bx-sort-down'
                    : 'bx-sort'
                } text-xl`} />
              </Button>
              <Button
                variant="ghost"
                onClick={() => handleSort('totalUsers')}
                className={`
                  flex items-center justify-center gap-2 rounded-lg px-6
                  hover:bg-gray-50 dark:hover:bg-white/5 transition-colors
                  ${sortConfig.key === 'totalUsers' 
                    ? 'text-blue-500 dark:text-blue-400' 
                    : 'text-gray-600 dark:text-gray-300'}
                `}
              >
                <i className="bx bx-user text-xl" />
                <i className={`bx ${
                  sortConfig.key === 'totalUsers' 
                    ? sortConfig.direction === 'asc' 
                      ? 'bx-sort-up' 
                      : 'bx-sort-down'
                    : 'bx-sort'
                } text-xl`} />
              </Button>
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden sm:flex sm:flex-row w-full sm:p-3 sm:gap-4">
            <div className="sm:w-auto relative z-[101] flex items-center">
              <Dropdown
                id="location-selector-desktop"
                buttonText={
                  <div className="flex items-center gap-2 px-3 w-full h-12 sm:min-w-[200px]">
                    <i className="bx bx-map-pin text-xl text-blue-500 dark:text-blue-400" />
                    <span className="flex-1 text-left text-gray-700 dark:text-gray-300 truncate text-base">
                      {t(`locations.${selectedLocation}`)}
                    </span>
                  </div>
                }
                items={locationDropdownItems}
                isOpen={isLocationOpen}
                setIsOpen={setIsLocationOpen}
                className="hover:bg-gray-50 dark:hover:bg-white/5 rounded-lg transition-colors relative w-full"
                menuClassName="z-[102] relative"
              />
            </div>

            <div className="h-12 w-[2px] bg-gray-200 dark:bg-white/10 self-center mx-2" />

            <div className="flex flex-row gap-3 items-center">
              <Button
                variant="ghost"
                onClick={() => handleSort('price')}
                className={`
                  flex items-center justify-center gap-2 px-4 h-12 rounded-lg
                  hover:bg-gray-50 dark:hover:bg-white/5 transition-colors text-base
                  ${sortConfig.key === 'price' 
                    ? 'text-blue-500 dark:text-blue-400' 
                    : 'text-gray-600 dark:text-gray-300'}
                `}
              >
                <i className="bx bx-dollar text-xl" />
                <span>{t('price')}</span>
                <i className={`bx ${
                  sortConfig.key === 'price' 
                    ? sortConfig.direction === 'asc' 
                      ? 'bx-sort-up' 
                      : 'bx-sort-down'
                    : 'bx-sort'
                } text-xl`} />
              </Button>
              <Button
                variant="ghost"
                onClick={() => handleSort('totalUsers')}
                className={`
                  flex items-center justify-center gap-2 px-4 h-12 rounded-lg
                  hover:bg-gray-50 dark:hover:bg-white/5 transition-colors text-base
                  ${sortConfig.key === 'totalUsers' 
                    ? 'text-blue-500 dark:text-blue-400' 
                    : 'text-gray-600 dark:text-gray-300'}
                `}
              >
                <i className="bx bx-user text-xl" />
                <span>{t('users')}</span>
                <i className={`bx ${
                  sortConfig.key === 'totalUsers' 
                    ? sortConfig.direction === 'asc' 
                      ? 'bx-sort-up' 
                      : 'bx-sort-down'
                    : 'bx-sort'
                } text-xl`} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const PriceDisplay = memo(({ price, billingPeriod, t }) => (
  <motion.div
    layout
    className="flex items-baseline justify-center h-14"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
  >
    <span className="text-sm mr-1 text-gray-900 dark:text-gray-100">$</span>
    <span className="text-4xl font-bold text-gray-900 dark:text-gray-100">{price}</span>
    <span className="text-gray-500 dark:text-gray-400 ml-2 min-w-[40px]">/ 
      {billingPeriod === 'monthly' ? t('pricing.month.short') : t('pricing.year.short')}
    </span>
  </motion.div>
));

const FeatureItem = memo(({ icon, text, index }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: index * 0.1, duration: 0.3 }}
    className="flex items-center space-x-3 text-gray-700 dark:text-gray-300 min-h-[24px]" // Added min-height
  >
    <i className={`bx ${icon} text-lg text-blue-500 w-5`}></i> {/* Added fixed width */}
    <span className="flex-1">{text}</span>
  </motion.div>
));

const ToggleSwitch = memo(({ billingPeriod, setBillingPeriod, t }) => (
  <div className="flex flex-col items-center">
    <div className="inline-flex p-1 rounded-full bg-blue-500">
      {[
        { id: 'monthly', text: t('pricing.billing.monthly') },
        { id: 'yearly', text: t('pricing.billing.yearly') }
      ].map((period) => (
        <motion.button
          key={period.id}
          className="relative px-6 py-2 text-sm font-medium rounded-full"
          onClick={() => setBillingPeriod(period.id)}
        >
          {billingPeriod === period.id && (
            <motion.div
              className="absolute inset-0 bg-white rounded-full"
              layoutId="switch-background"
              transition={{ duration: 0.3, ease: 'easeOut' }}
            />
          )}
          <span className={`relative z-10 ${
            billingPeriod === period.id ? 'text-blue-500' : 'text-white'
          }`}>
            {period.text}
          </span>
        </motion.button>
      ))}
    </div>
  </div>
));

const Pricing = () => {
  const { t } = useLanguage();
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [selectedLocation, setSelectedLocation] = useState('de-fra');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const locations = {
    'de-fra': {
      name: t('locations.de-fra'),
      icon: 'bx-map-pin',
      servers: [
        {
          name: 'DE-Ryzen-0',
          monthly: 2.99,
          yearly: 35.88,
          specs: {
            cpu: '1 vCPU AMD Ryzen 9 5950X',
            ram: '2 GB RAM',
            storage: '20 GB NVMe',
          },
          totalUsers: 2854
        },
        {
          name: 'DE-Ryzen-1',
          monthly: 4.69,
          yearly: 56.28,
          specs: {
            cpu: '2 vCPU AMD Ryzen 9 5950X',
            ram: '4 GB RAM',
            storage: '40 GB NVMe',
          },
          isPopular: true,
          totalUsers: 3102
        },
        {
          name: 'DE-Ryzen-2',
          monthly: 8.69,
          yearly: 104.28,
          specs: {
            cpu: '4 vCPU AMD Ryzen 9 5950X',
            ram: '8 GB RAM',
            storage: '80 GB NVMe',
          },
          totalUsers: 2945
        },
        {
          name: 'DE-Ryzen-3',
          monthly: 9.99,
          yearly: 119.88,
          specs: {
            cpu: '6 vCPU AMD Ryzen 9 5950X',
            ram: '16 GB RAM',
            storage: '160 GB NVMe',
          },
          totalUsers: 1876
        },
        {
          name: 'DE-Ryzen-4',
          monthly: 15.99,
          yearly: 191.88,
          specs: {
            cpu: '8 vCPU AMD Ryzen 9 5950X',
            ram: '32 GB RAM',
            storage: '320 GB NVMe',
          },
          totalUsers: 1243
        }
      ]
    },
  };

  const commonFeatures = useMemo(() => [
    { icon: 'bx-transfer-alt', text: t('pricing.features.traffic') },
    { icon: 'bx-shield-quarter', text: t('pricing.features.ddos') },
    { icon: 'bx-support', text: t('pricing.features.support') },
    { icon: 'bx-timer', text: t('pricing.features.uptime') },
    { icon: 'bx-line-chart', text: t('pricing.features.monitoring') }
  ], [t]);

  const handleLocationChange = useCallback((location) => {
    setSelectedLocation(location);
  }, []);

  const handleSortChange = useCallback((sortUpdateFn) => {
    setSortConfig(sortUpdateFn);
  }, []);

const getPrice = useCallback((plan) => {
  if (billingPeriod === 'monthly') {
    return plan.monthly;
  }
  // Return full yearly price instead of dividing by 12
  return plan.yearly;
}, [billingPeriod]);

// Update the sort function to handle full yearly prices
const sortPlans = useCallback((plansToSort) => {
  if (!sortConfig.key) return plansToSort;

  return [...plansToSort].sort((a, b) => {
    let aValue = sortConfig.key === 'price' ? 
      (billingPeriod === 'monthly' ? a.monthly : a.yearly) : // Changed from a.yearly / 12
      a[sortConfig.key];
    let bValue = sortConfig.key === 'price' ? 
      (billingPeriod === 'monthly' ? b.monthly : b.yearly) : // Changed from b.yearly / 12
      b[sortConfig.key];

    if (aValue < bValue) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
}, [sortConfig.key, sortConfig.direction, billingPeriod]);

  const filteredAndSortedPlans = useMemo(() => {
    const currentLocation = locations[selectedLocation];
    return sortPlans(currentLocation.servers);
  }, [selectedLocation, sortPlans, locations]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.08
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        delay: index * 0.1,
        ease: 'easeOut'
      }
    }),
    hover: {
      y: -8,
      transition: {
        duration: 0.3,
        ease: 'easeOut'
      }
    }
  };

  return (
    <LayoutGroup>
      <section className="relative bg-gradient-to-b from-gray-50 via-white to-gray-50 dark:from-gray-900 dark:via-blue-950 dark:to-gray-900" id="pricing">
        {/* Background elements remain the same */}
        
        <div className="relative container mx-auto px-4 py-20">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="text-center max-w-3xl mx-auto mb-6"
          >
            <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-blue-800 to-blue-900 dark:from-white dark:via-blue-100 dark:to-blue-200 min-h-[48px]"> {/* Added min-height */}
              {t('pricing.title')}
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-8 min-h-[48px]"> {/* Added min-height */}
              {t('pricing.description')}
            </p>
            
            <div className="h-12"> {/* Added fixed height container */}
              <ToggleSwitch 
                billingPeriod={billingPeriod}
                setBillingPeriod={setBillingPeriod}
                t={t}
              />
            </div>
          </motion.div>

          <FiltersBar 
            selectedLocation={selectedLocation}
            onLocationChange={handleLocationChange}
            sortConfig={sortConfig}
            onSortChange={handleSortChange}
            locations={locations}
            t={t}
          />

          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6"
          >
            <AnimatePresence mode="wait">
              {filteredAndSortedPlans.map((plan, index) => (
                <motion.div
                  key={plan.name}
                  custom={index}
                  variants={cardVariants}
                  whileHover="hover"
                >
                  <Card 
                    className={`h-full bg-white dark:bg-white/5 backdrop-blur-lg ${
                      plan.isPopular 
                        ? 'border-2 border-blue-500 dark:border-blue-400 relative shadow-lg' 
                        : 'border border-gray-200 dark:border-white/10'
                    }`}
                  >
                    {plan.isPopular && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="absolute -top-3 left-0 right-0 flex justify-center h-8" // Added fixed height
                      >
                        <span className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                          {t('pricing.popular.choice')}
                        </span>
                      </motion.div>
                    )}

                    <div className="p-6">
                      <motion.div layout className="text-center mb-8 min-h-[160px]"> {/* Added min-height */}
                        <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-1 min-h-[28px]">{plan.name}</h3>
                        <div className="flex items-center justify-center text-sm font-medium text-gray-500 dark:text-gray-400 mb-4 min-h-[3rem]">
                          <i className="bx bx-map-pin mr-1 text-blue-400 flex-shrink-0" />
                          <span className="text-center">
                            {t(`locations.${selectedLocation}`)}
                          </span>
                        </div>
                        <AnimatePresence mode="wait">
                          <PriceDisplay 
                            key={billingPeriod}
                            price={getPrice(plan)}
                            billingPeriod={billingPeriod}
                            t={t}
                          />
                        </AnimatePresence>
                        <div className="text-sm text-gray-500 dark:text-gray-400 mt-2 h-6"> {/* Added fixed height */}
                          <div className="flex items-center justify-center gap-1">
                            <i className="bx bx-user text-lg" />
                            {plan.totalUsers.toLocaleString()} {t('pricing.active.clients')}
                          </div>
                        </div>
                      </motion.div>

                      <div className="space-y-6">
                        <div className="space-y-3 border-b border-gray-200 dark:border-white/10 pb-4 min-h-[180px]"> {/* Added min-height */}
                          <h4 className="font-medium text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-3 h-5"> {/* Added fixed height */}
                            {t('pricing.specs')}
                          </h4>
                          <div className="space-y-3">
                            {Object.entries(plan.specs).map(([key, value], idx) => (
                              <FeatureItem 
                                key={key}
                                icon={key === 'cpu' ? 'bxs-chip' : key === 'ram' ? 'bxs-memory-card' : 'bxs-hdd'}
                                text={value}
                                index={idx}
                              />
                            ))}
                          </div>
                        </div>

                        <div className="space-y-3 min-h-[280px]"> {/* Added min-height */}
                          <h4 className="font-medium text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-3 h-5"> {/* Added fixed height */}
                            {t('pricing.included')}
                          </h4>
                          {commonFeatures.map((feature, idx) => (
                            <FeatureItem 
                              key={idx}
                              icon={feature.icon}
                              text={feature.text}
                              index={idx}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="mt-8 h-12"> {/* Added fixed height */}
                        <Button
                          variant={plan.isPopular ? 'primary' : 'outline'}
                          className={`w-full ${plan.isPopular ? '' : 'dark:border-white/10 dark:hover:bg-white/10'}`}
                          onClick={() => window.location.href = 'https://my.nobuxe.com/'}
                        >
                          {t('pricing.select.plan')}
                        </Button>
                      </div>
                    </div>
                  </Card>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4 }}
            className="mt-16 text-center min-h-[120px]" // Added min-height
          >
            <p className="text-gray-600 dark:text-gray-400 mb-4 min-h-[24px]"> {/* Added min-height */}
              {t('pricing.custom.plan')}
            </p>
            <Button 
              variant="outline"
              className="dark:border-white/10 dark:hover:bg-white/10"
              onClick={() => window.location.href = 'https://t.me/nobuxe_supbot'}
            >
              {t('pricing.contact.us')}
            </Button>
          </motion.div>
        </div>
      </section>
    </LayoutGroup>
  );
};

export default Pricing;