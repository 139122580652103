import React, { useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { motion } from 'framer-motion';

const AboutPage = () => {
  const { t } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative w-full min-h-screen bg-gray-50 dark:bg-gradient-to-b dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
      {/* Background Elements - только для темной темы */}
      <div className="absolute inset-0 w-full h-full overflow-hidden dark:block hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-30" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent" />
        
        <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
      </div>

      <div className="relative w-full max-w-[1200px] mx-auto px-4 py-20">
        {/* Hero Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h1 className="mt-8 text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 dark:bg-clip-text dark:text-transparent dark:bg-gradient-to-r dark:from-white dark:via-blue-100 dark:to-blue-200 tracking-tight mb-6">
            {t('about.title')}
          </h1>
          <p className="text-lg md:text-xl text-gray-600 dark:text-blue-100 mb-4">
            {t('about.subtitle')}
          </p>
        </motion.div>

        {/* Company Info */}
        <div className="grid md:grid-cols-2 gap-12 mb-16">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-6 dark:border dark:border-white/5 space-y-6"
          >
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
              {t('about.our.story')}
            </h2>
            <p className="text-gray-600 dark:text-blue-100">
              {t('about.story.content')}
            </p>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-6 dark:border dark:border-white/5 space-y-6"
          >
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
              {t('about.our.mission')}
            </h2>
            <p className="text-gray-600 dark:text-blue-100">
              {t('about.mission.content')}
            </p>
          </motion.div>
        </div>

        {/* Features */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {[
            {
              icon: 'bx-shield-quarter',
              title: t('about.feature1.title'),
              description: t('about.feature1.description')
            },
            {
              icon: 'bx-support',
              title: t('about.feature2.title'),
              description: t('about.feature2.description')
            },
            {
              icon: 'bx-rocket',
              title: t('about.feature3.title'),
              description: t('about.feature3.description')
            }
          ].map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-6 dark:border dark:border-white/5 hover:bg-gray-50 dark:hover:bg-white/10 transition-all duration-300"
            >
              <i className={`bx ${feature.icon} text-4xl text-blue-600 dark:text-blue-400 mb-4`}></i>
              <h3 className="text-xl font-semibold mb-3 text-gray-900 dark:text-white">
                {feature.title}
              </h3>
              <p className="text-gray-600 dark:text-blue-100">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>

        {/* Team Section */}
        {/*<motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <h2 className="text-3xl font-bold mb-12 text-gray-900 dark:text-white">
            {t('about.our.team')}
          </h2>
          <div className="grid md:grid-cols-4 gap-8">
            {[1, 2, 3, 4].map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-6 dark:border dark:border-white/5 hover:bg-gray-50 dark:hover:bg-white/10 transition-all duration-300"
              >
                <div className="p-6">
                  <img 
                    src={`/api/placeholder/150/150`}
                    alt={t(`about.team.member${member}.name`)}
                    className="w-24 h-24 rounded-full mx-auto mb-4"
                  />
                  <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
                    {t(`about.team.member${member}.name`)}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-blue-100">
                    {t(`about.team.member${member}.position`)}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>*/}
      </div>
    </div>
  );
};

export default AboutPage;