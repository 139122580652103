import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Disclosure } from '@headlessui/react';
import { useLanguage } from '../../contexts/LanguageContext';

const FAQ = () => {
  const { t } = useLanguage();

  const faqs = [
    {
      question: t('faq.how.to.start.question'),
      answer: t('faq.how.to.start.answer')
    },
    {
      question: t('faq.virtualization.question'),
      answer: t('faq.virtualization.answer')
    },
    {
      question: t('faq.change.plan.question'),
      answer: t('faq.change.plan.answer')
    },
    {
      question: t('faq.data.security.question'),
      answer: t('faq.data.security.answer')
    },
    {
      question: t('faq.ddos.protection.question'),
      answer: t('faq.ddos.protection.answer')
    },
    {
      question: t('faq.support.question'),
      answer: t('faq.support.answer')
    }
  ];

  return (
    <section className="py-20 bg-white dark:bg-gray-900" id="faq">
      <div className="container mx-auto px-4">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center max-w-3xl mx-auto mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-6">
            {t('faq.title')}
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            {t('faq.description')}
          </p>
        </motion.div>

        {/* FAQ Items */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto divide-y divide-gray-200 dark:divide-gray-700"
        >
          {faqs.map((faq, index) => (
            <Disclosure key={index} as="div" className="py-6">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center text-left">
                    <span className="text-lg font-medium text-gray-900 dark:text-white">
                      {faq.question}
                    </span>
                    <motion.span
                      animate={{ rotate: open ? 180 : 0 }}
                      transition={{ duration: 0.2 }}
                      className="ml-6 flex-shrink-0"
                    >
                      <i className={`bx bx-chevron-down text-2xl text-blue-500
                        transition-transform duration-200
                        ${open ? 'transform rotate-180' : ''}
                      `}></i>
                    </motion.span>
                  </Disclosure.Button>

                  <AnimatePresence initial={false}>
                    {open && (
                      <Disclosure.Panel
                        static
                        as={motion.div}
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="overflow-hidden"
                      >
                        <motion.div
                          initial={{ y: -10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className="mt-4 text-gray-600 dark:text-gray-400 space-y-2"
                        >
                          {faq.answer.split('\n').map((line, i) => (
                            <p key={i} className="leading-relaxed">
                              {line}
                            </p>
                          ))}
                        </motion.div>
                      </Disclosure.Panel>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Disclosure>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default FAQ;