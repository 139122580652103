import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../../contexts/LanguageContext';
import { m, motion } from 'framer-motion';

export const PrivacyPolicy = () => {
  const { t } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut'
      }
    }
  };

  const privacyContent = {
    title: "Политика конфиденциальности",
    intro: "Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федеральных законов ЕС «О персональных данных» и Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных «nobuxe.com» (далее — Оператор).",
    sections: [
      {
        title: "1. Общие положения",
        items: [
          "Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, включая защиту прав на неприкосновенность частной жизни, личную и семейную тайну.",
          "Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта nobuxe.com."
        ]
      },
      {
        title: "2. Основные понятия",
        items: [
          "Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники;",
          "Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);",
          "Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу nobuxe.com;",
          "Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств;",
          "Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю;",
          "Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными;",
          "Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных;",
          "Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта nobuxe.com;",
          "Пользователь — любой посетитель веб-сайта nobuxe.com;",
          "Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;",
          "Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц;",
          "Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства;",
          "Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно;"
        ]
      },
      {
        title: "3. Список данных для обработки",
        items: [
          "Оператор может обрабатывать следующие персональные данные Пользователя:",
          "фамилия, имя, отчество;",
          "электронный адрес;",
          "адрес фактического места проживания и регистрации по месту жительства и (или) по месту пребывания;",
          "паспортные данные.",
          "Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов cookies) с помощью сервисов интернет-статистики (Яндекс Метрика, Google Analytics и других).",
          "Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные."
        ]
      },
      {
        title: "4. Цели обработки персональных данных",
        items: [
          "Информирование Пользователя посредством отправки электронных писем;",
          "Заключение, исполнение и прекращение гражданско-правовых договоров;",
          "Предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте.",
          "Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений.",
          "Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания."
        ]
      },
      {
        title: "5. Правовые основания обработки персональных данных",
        items: [
          "Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы.",
          "Оператор обрабатывает обезличенные данные о Пользователе, если это разрешено в настройках браузера Пользователя (включено сохранение файлов cookies и использование технологии JavaScript)."
        ]
      },
      {
        title: "6. Порядок сбора, хранения, передачи и других видов обработки персональных данных",
        items: [
          "Безопасность персональных данных обеспечивается путем реализации правовых, организационных и технических мер.",
          "Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к ним неуполномоченных лиц.",
          "Персональные данные Пользователя никогда не будут переданы третьим лицам, за исключением случаев, связанных с исполнением законодательства.",
          "Пользователь может актуализировать свои персональные данные через электронную почту support@nobuxe.com.",
          "Срок обработки персональных данных является неограниченным. Пользователь может отозвать согласие на обработку персональных данных."
        ]
      },
      {
        title: "7. Трансграничная передача персональных данных",
        items: [
          "Оператор до начала трансграничной передачи персональных данных обязан убедиться в надёжной защите прав субъектов персональных данных.",
          "Передача данных на территорию государств, не обеспечивающих надёжную защиту прав субъектов, возможна только при наличии письменного согласия."
        ]
      },
      {
        title: "8. Заключительные положения",
        items: [
          "Пользователь может получить разъяснения по вопросам обработки персональных данных по электронной почте support@nobuxe.com.",
          "В документе будут отражены любые изменения политики обработки персональных данных. Политика действует бессрочно до замены её новой версией.",
          "Актуальная версия Политики находится в свободном доступе в сети Интернет по адресу https://nobuxe.com/privacy."
        ]
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>Политика конфиденциальности | Nobuxe - Защита ваших данных</title>
        <meta 
          name="description" 
          content="Политика конфиденциальности Nobuxe. Узнайте, как мы защищаем ваши персональные данные и обеспечиваем их безопасность в соответствии с законодательством." 
        />
        <meta 
          name="keywords" 
          content="политика конфиденциальности, защита данных, персональные данные, конфиденциальность, nobuxe" 
        />
        {/* Open Graph */}
        <meta 
          property="og:title" 
          content="Политика конфиденциальности Nobuxe" 
        />
        <meta 
          property="og:description" 
          content="Ознакомьтесь с нашей политикой конфиденциальности и узнайте, как мы обрабатываем и защищаем ваши данные." 
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nobuxe.com/privacy" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta 
          name="twitter:title" 
          content="Политика конфиденциальности Nobuxe" 
        />
        <meta 
          name="twitter:description" 
          content="Ознакомьтесь с нашей политикой конфиденциальности и узнайте, как мы обрабатываем и защищаем ваши данные." 
        />
        {/* Canonical URL */}
        <link rel="canonical" href="https://nobuxe.com/privacy" />
      </Helmet>

      <section className="relative w-full min-h-screen bg-gradient-to-b from-gray-50 via-white to-gray-50 dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
        {/* Background effects */}
        <div className="absolute inset-0 w-full h-full overflow-hidden">
          <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-5 dark:opacity-30" />
          
          {/* Gradient orbs */}
          <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
          <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
          <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        </div>

        <div className="relative container mx-auto px-4 py-24">
          {/* Title */}
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-blue-800 to-blue-900 dark:from-white dark:via-blue-100 dark:to-blue-200"
          >
            {privacyContent.title}
          </motion.h1>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {/* Introduction */}
            <motion.div 
              variants={itemVariants}
              className="bg-white/80 dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 mb-8 shadow-xl dark:shadow-none"
            >
              <p className="text-gray-700 dark:text-gray-300">
                {privacyContent.intro}
              </p>
            </motion.div>

            {/* Sections */}
            {privacyContent.sections.map((section, index) => (
              <motion.div 
                key={index}
                variants={itemVariants}
                className="bg-white/80 dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 mb-8 shadow-xl dark:shadow-none"
              >
                <h2 className="text-2xl text-blue-500 dark:text-blue-400 mb-6">
                  {section.title}
                </h2>
                <div className="space-y-4">
                  {section.items.map((item, itemIndex) => (
                    <motion.div 
                      key={itemIndex}
                      variants={itemVariants}
                      className="flex gap-3 items-start"
                    >
                      <i className="bx bx-chevron-right text-blue-500 dark:text-blue-400 text-2xl mt-1"></i>
                      <span className="text-gray-700 dark:text-gray-300">
                        {item}
                      </span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;