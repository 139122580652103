import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { useLanguage } from '../../contexts/LanguageContext';
import Button from '../ui/Button';
import Dropdown from '../ui/Dropdown';
import flagDE from "/flags/de.png";
import flagRU from "/flags/ru.png";
import flagEN from "/flags/gb.png";

const Header = () => {
  const { theme, toggleTheme } = useTheme();
  const { currentLanguage, changeLanguage, t } = useLanguage();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServersDropdownOpen, setIsServersDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const shouldBeTransparent = isHomePage && !isScrolled && !isMobileMenuOpen;

  const logoColor = theme === 'dark' || shouldBeTransparent ? '#FFFFFF' : '#2563eb';

  const handleServerSelect = (path) => {
    setIsServersDropdownOpen(false);
    setIsMobileMenuOpen(false);
    navigate(path);
  };

  const handlePricingClick = (e) => {
    e.preventDefault();
    if (isHomePage) {
      // If we're on the home page, smoothly scroll to the pricing section
      document.querySelector('#pricing')?.scrollIntoView({ behavior: 'smooth' });
    } else {
      // If we're on another page, navigate to home and then scroll to pricing
      navigate('/', { state: { scrollTo: 'pricing' } });
    }
    setIsMobileMenuOpen(false);
  };

  // Effect to handle scrolling after navigation
  useEffect(() => {
    if (location.state?.scrollTo === 'pricing') {
      // Small timeout to ensure the content is loaded
      setTimeout(() => {
        document.querySelector('#pricing')?.scrollIntoView({ behavior: 'smooth' });
        // Clean up the state
        navigate(location.pathname, { replace: true, state: {} });
      }, 100);
    }
  }, [location.state]);

  const serverItems = [
    {
      image: flagDE,
      title: t('germany'),
      onClick: () => handleServerSelect('/de-servers')
    }
  ];

  const languageOptions = [
    {
      image: flagRU,
      title: "Русский",
      code: "ru",
      onClick: () => changeLanguage('ru')
    },
    {
      image: flagEN,
      title: "English",
      code: "en",
      onClick: () => changeLanguage('en')
    }
  ];

  return (
    <header className={`
      fixed top-0 left-0 right-0 z-[999] transition-all duration-300
      ${isMobileMenuOpen ? 'bg-white dark:bg-gray-900' : 
        shouldBeTransparent ? 'bg-transparent' : 
        'bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg shadow-lg'}
    `}>
      <nav className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/">
            <motion.div
              className="flex items-center space-x-2"
              whileHover={{ scale: 1.05 }}
            >
              <img 
                src="/logo.svg" 
                alt="Nobuxe" 
                className="h-8 w-auto" 
                style={{ filter: `brightness(0) saturate(100%) ${logoColor === '#FFFFFF' ? 'invert(1)' : 'invert(0)'}`}}
              />
              <span className={`font-bold text-xl ${shouldBeTransparent ? 'text-white' : 'text-blue-600 dark:text-white'}`}>
                Nobuxe
              </span>
            </motion.div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <div className="flex items-center space-x-8">
              <Dropdown 
                isOpen={isServersDropdownOpen}
                setIsOpen={setIsServersDropdownOpen}
                isScrolled={!shouldBeTransparent}
                mobile={false}
                buttonText={t('virtual.servers')}
                items={serverItems}
              />
              <NavLinks isScrolled={!shouldBeTransparent} onPricingClick={handlePricingClick} />
            </div>
            <div className="flex items-center space-x-4">
              <Dropdown 
                isOpen={isLanguageDropdownOpen}
                setIsOpen={setIsLanguageDropdownOpen}
                isScrolled={!shouldBeTransparent}
                mobile={false}
                buttonText={
                  <div className="flex items-center space-x-2">
                    <img 
                      src={currentLanguage === 'ru' ? flagRU : flagEN} 
                      alt={currentLanguage.toUpperCase()} 
                      className="w-5 h-4 rounded-[1px] object-cover"
                    />
                    <span>{currentLanguage.toUpperCase()}</span>
                  </div>
                }
                items={languageOptions}
              />
              <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
              <Button 
                variant="primary" 
                size="sm"
                onClick={() => window.location.href = 'https://my.nobuxe.com/'}
              >
                {t('go.to.panel')}
              </Button>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <i className={`bx ${isMobileMenuOpen ? 'bx-x' : 'bx-menu'} text-2xl
              ${shouldBeTransparent ? 'text-white' : 'text-gray-900 dark:text-white'}
            `}></i>
          </button>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden bg-white dark:bg-gray-900"
            >
              <div className="py-4 space-y-4">
                <div className="flex flex-col space-y-2">
                  <Dropdown 
                    isOpen={isServersDropdownOpen}
                    setIsOpen={setIsServersDropdownOpen}
                    isScrolled={true}
                    mobile={true}
                    buttonText={t('virtual.servers')}
                    items={serverItems}
                  />
                  <Dropdown 
                    isOpen={isLanguageDropdownOpen}
                    setIsOpen={setIsLanguageDropdownOpen}
                    isScrolled={true}
                    mobile={true}
                    buttonText={
                      <div className="flex items-center space-x-2">
                        <img 
                          src={currentLanguage === 'ru' ? flagRU : flagEN} 
                          alt={currentLanguage.toUpperCase()} 
                          className="w-5 h-4 rounded-[1px] object-cover"
                        />
                        <span>{currentLanguage.toUpperCase()}</span>
                      </div>
                    }
                    items={languageOptions}
                  />
                  <NavLinks mobile isScrolled={true} onPricingClick={handlePricingClick} />
                </div>
                <div className="flex items-center justify-between pt-4">
                  <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
                  <Button 
                    variant="primary" 
                    size="sm"
                    onClick={() => window.location.href = 'https://my.nobuxe.com/'}
                  >
                    {t('go.to.panel')}
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
};

const NavLinks = ({ mobile, isScrolled, onPricingClick }) => {
  const { t } = useLanguage();
  
  const baseClasses = `
    transition-colors duration-200
    ${isScrolled ? 'text-gray-600 hover:text-blue-600 dark:text-gray-300 dark:hover:text-white' 
    : 'text-white/90 hover:text-white'}
  `;

  const links = [
    { title: t('prices'), href: '#pricing', onClick: onPricingClick },
    { title: t('about'), to: '/about' },  
    { title: t('contacts'), to: '/contact' },  
    { title: t('news'), to: '/news' },
    { title: t('referral.program'), to: '/referral-program' }
  ];

  return (
    <div className={mobile ? 'flex flex-col space-y-4' : 'flex space-x-8'}>
      {links.map((link) => {
        const Component = link.to ? Link : motion.a;
        const navigationProps = link.to ? 
          { to: link.to } : 
          { 
            href: link.href,
            onClick: link.onClick || undefined
          };

        return (
          <Component
            key={link.to || link.href}
            {...navigationProps}
            className={`${baseClasses} flex items-center space-x-2`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {link.icon && <i className={`bx ${link.icon}`}></i>}
            <span>{link.title}</span>
          </Component>
        );
      })}
    </div>
  );
};

const ThemeToggle = ({ theme, toggleTheme }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={toggleTheme}
      className="p-2"
    >
      {theme === 'dark' ? (
        <i className="bx bx-sun text-xl text-yellow-500"></i>
      ) : (
        <i className="bx bx-moon text-xl text-blue-600"></i>
      )}
    </motion.button>
  );
};

export default Header;