import React from 'react';
import { cn } from "../../utils";

const EmailInput = React.forwardRef(({ className, disabled, ...props }, ref) => {
  return (
    <input
      type="email"
      className={cn(
        "w-full px-6 py-4 rounded-xl",
        "bg-white/5 border border-white/10",
        "text-white text-lg placeholder:text-gray-400",
        "transition-all duration-300",
        "focus:outline-none focus:ring-0",
        "focus:border-blue-500/50 focus:bg-white/10",
        "disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      disabled={disabled}
      ref={ref}
      {...props}
    />
  );
});

EmailInput.displayName = "EmailInput";

export { EmailInput };