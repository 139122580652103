import React, { useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet-async';
import { m, motion } from 'framer-motion';

export const TermsOfService = () => {
  const { t } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut'
      }
    }
  };

  const termsContent = {
    title: "Условия использования",
    intro: "Хостинг компания nobuxe.com, именуемая в дальнейшем «Компания», предлагает услуги аренды виртуальных и выделенных серверов, а также смежные услуги любому юридическому или физическому лицу, именуемому в дальнейшем «Клиент», на условиях настоящей Оферты.",
    sections: [
      {
        title: "Зона ответственности Компании",
        items: [
          "Проблемы с хост-машинами и связью — ответственность Компании.",
          "Проблемы с операционной системой, любым программным обеспечением, установленным на виртуальном сервере Клиентом — ответственность Клиента."
        ]
      },
      {
        title: "Порядок оплаты",
        items: [
          "Услуги предоставляются на основании 100% оплаты в течение 3 (трёх) рабочих дней после оплаты.",
          "Оплата физическими лицами производится только посредством электронных платежей.",
          "Оплата Хостинга производится в рублях на основании действующих тарифов.",
          "К оплате может добавляться комиссия посредников и платежных систем.",
          "В случае отсутствия оплаты следующего периода Хостинга, Компания приостанавливает предоставление услуг.",
          "После приостановки предоставления услуг данные сохраняются в течение 8 (восьми) часов для тарифов, оплаченных на месяц и более, и 3 часа для подневных.",
          "В случае поступления полной оплаты после приостановки предоставления услуги возобновление предоставления услуги производится в течение 24 часов.",
          "При подключении опции «Автоплатеж» Клиент дает разрешение Компании списывать денежные средства с привязанной банковской карты.",
          "Клиент имеет право отключить автоплатежи в любой момент в личном кабинете."
        ]
      },
      {
        title: "Возврат средств",
        items: [
          "Возврат денежных средств возможен только в случае неработоспособности сервера по вине Компании.",
          "Возврат средств осуществляется в течение 24 часов на счет, с которого производилась оплата.",
          "Для возврата средств необходимо написать письмо-запрос в поддержку.",
          "В случае нарушения условий Компания вправе отказать в возврате средств.",
          "Если по вине Клиента были понесены убытки, они вычитаются из суммы возврата.",
          "Возврат может быть отказан при установлении факта мошенничества.",
          "При спорах о геолокации решение принимается на основе данных RIPE NCC."
        ]
      },
      {
        title: "Права и обязанности Клиента",
        items: [
          "Клиенту предоставляется доступ к серверу с полной ответственностью.",
          "Разрешается установка легального ПО.",
          "Обязательно соблюдение законодательства РФ.",
          "Хостинг можно использовать в любых законных целях.",
          "Поддержка осуществляется через личный кабинет.",
          "При частых блокировках услуга может быть остановлена.",
          "Игнорирование жалоб ведет к блокировке.",
          "Компания может остановить услугу без уведомления.",
          "Сроки устранения жалоб не гарантируются.",
          "Данные с заблокированного сервера могут не предоставляться.",
          "Множественные аккаунты запрещены."
        ]
      },
      {
        title: "Права и обязанности Компании",
        items: [
          "Услуги предоставляются «как есть».",
          "Обязательство поддержки качества хост-машин.",
          "Нет гарантии 100% доступности.",
          "Плановые работы с 4:00 до 10:00 МСК.",
          "Не отвечаем за проблемы третьих лиц.",
          "Возможны остановки при форс-мажоре.",
          "Техподдержка по запросу.",
          "Не отвечаем за стороннее ПО."
        ]
      },
      {
        title: "Запрещенный контент и ПО",
        items: [
          "На Хостинге запрещено размещение порнографии, в том числе ссылок и рекламы, содержащей порнографические сцены.",
          "Запрещено размещение информации, ПО, противоречащих и/или запрещенных законодательством РФ, законом об авторских и смежных правах.",
          "Запрещено размещение ПО для ботнетов, майнинга, граббинга, фишинга, прочих целей, явно противоречащих легальной работе в сети Интернет.",
          "Осуществление массовых почтовых рассылок запрещено, кроме случаев, санкционированных самой Компанией.",
          "Запрещено размещение сервисов (в том числе с платным или приватным доступом), которые могут служить вспомогательными средствами для противоправных действий в сети Интернет.",
          "Запрещено размещение сетевых сканеров, прокси-чекеров и подобного ПО.",
          "Скорость сети на виртуальных серверах ограничена до 500 Мбит. Нет никаких соотношений Россия/зарубеж или входящий/исходящий. С целью обеспечения клиентам комфортной работы с серверами мы применяем «политику честного использования» (Fair Use Policy) трафика, исключая возможность злоупотребления или неправомерного использования канала отдельными клиентами. Мы имеем право после достижения сервером определенного объема трафика (который может определяться отдельно для каждого тарифа), снизить для данного заказа скорость доступа в сеть до конца расчетного месяца (shaping) до 10 Мбит или 100 Мбит."
        ]
      },
      {
        title: "Прекращение предоставления услуг по инициативе Компании",
        items: [
          "Компания оставляет за собой право прекратить предоставление услуг по собственному усмотрению, немедленно и без предупреждения в следующих случаях:",
          "Несанкционированного распространения или копирования программного обеспечения, защищенного авторским правом, нарушения законов Российской Федерации, других международных норм и соглашений, экспортных ограничений, мошенничества, оборота порнографических материалов, наркотиков и других незаконных видов деятельности;",
          "Массовых почтовых рассылок, на получение которых не давалось явного согласия пользователей;",
          "Клеветы, распространения личной информации, нарушения авторских и смежных прав, интеллектуальных прав;",
          "Размещения порнографического контента во всех его проявлениях, в том числе ссылок на другие сайты с порнографическим контентом;",
          "Нарушения прав частной жизни и собственности; размещения клеветнической и оскорбительной информации, пособничества, подстрекательств к межнациональной розни, убийству, терроризму, насилию в любых формах;",
          "Препятствования нормальной и стабильной работе серверов, сетевых ресурсов, общей работе Компании;",
          "Указания заведомо недостоверных данных Клиента или отказа их подтверждения;",
          "Некорректного общения с сотрудниками Компании, в том числе употребления ненормативной лексики и распространения клеветнической информации о Компании;",
          "Компания оставляет за собой право передачи информации о Клиенте в случае официального запроса от правоохранительных органов."
        ]
      },
      {
        title: "Компания не несет ответственности",
        items: [
          "За выбор Клиентом операционной системы, ПО и последствия, которые это может повлечь.",
          "Ни за какие виды прямого и косвенного ущерба, потерю информации, деловой репутации и прочие случаи ущерба.",
          "Компания не проводит проверку контента Хостинга Клиента. Исключение составляют случаи поступления мотивированной жалобы от третьей стороны."
        ]
      },
      {
        title: "Гарантия уровня сервиса",
        items: [
          "Компания гарантирует сохранение доступности серверов Хостинга на уровне 99% в месяц.",
          "В случае нарушения пункта 9.1 Настоящей Оферты каждый полный день (24 часа) простоя услуги компенсируется Клиенту в двойном размере.",
          "Компенсация начисляется только в виде дополнительных дней обслуживания и не может быть выплачена в денежном выражении.",
          "Компенсация начисляется на основании запроса в поддержку в личном кабинете."
        ]
      },
      {
        title: "E-mail информирование",
        items: [
          "Регистрация Клиента в личном кабинете означает автоматическое согласие Клиента с получением информационных e-mail сообщений, в том числе с предложениями услуг Компании.",
          "Отказ от получения уведомлений возможен только в случае полного закрытия аккаунта Клиента.",
          "Компания снимает с себя ответственность за неполучение Клиентом важных информационных сообщений посредством e-mail сообщений."
        ]
      },
      {
        title: "Реквизиты Компании",
        items: [
          "E-mail: support@nobuxe.com",
          "Официальный сайт: https://nobuxe.com",
          "Личный кабинет: https://my.nobuxe.com/"
        ],
        isContactSection: true // Добавим специальный флаг для контактной секции
      }
    ]
  };

  return (
  <>
    <Helmet>
    <title>Условия использования | Nobuxe - Правила и положения</title>
    <meta 
      name="description" 
      content="Условия использования сервиса Nobuxe. Ознакомьтесь с правилами предоставления услуг аренды виртуальных серверов, правами и обязанностями сторон." 
    />
    <meta 
      name="keywords" 
      content="условия использования, правила, terms of service, vps хостинг, аренда серверов, nobuxe" 
    />
    {/* Open Graph */}
    <meta 
      property="og:title" 
      content="Условия использования сервиса Nobuxe" 
    />
    <meta 
      property="og:description" 
      content="Правила предоставления услуг хостинга, права и обязанности клиентов, условия оплаты и использования серверов Nobuxe." 
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://nobuxe.com/terms" />
    {/* Twitter Card */}
    <meta name="twitter:card" content="summary" />
    <meta 
      name="twitter:title" 
      content="Условия использования Nobuxe" 
    />
    <meta 
      name="twitter:description" 
      content="Правила предоставления услуг хостинга, права и обязанности клиентов, условия оплаты и использования серверов Nobuxe." 
    />
    {/* Canonical URL */}
    <link rel="canonical" href="https://nobuxe.com/terms" />
  </Helmet>
    <section className="relative w-full min-h-screen bg-gradient-to-b from-gray-50 via-white to-gray-50 dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
      {/* Background effects */}
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-5 dark:opacity-30" />
        
        {/* Gradient orbs */}
        <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
      </div>

      <div className="relative container mx-auto px-4 py-24">
        {/* Title */}
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-blue-800 to-blue-900 dark:from-white dark:via-blue-100 dark:to-blue-200"
        >
          {termsContent.title}
        </motion.h1>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Introduction */}
          <motion.div 
            variants={itemVariants}
            className="bg-white/80 dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 mb-8 shadow-xl dark:shadow-none"
          >
            <p className="text-gray-700 dark:text-gray-300">
              {termsContent.intro}
            </p>
          </motion.div>

          {/* Sections */}
          {termsContent.sections.map((section, index) => (
            <motion.div 
              key={index}
              variants={itemVariants}
              className="bg-white/80 dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 mb-8 shadow-xl dark:shadow-none"
            >
              <h2 className="text-2xl text-blue-500 dark:text-blue-400 mb-6">
                {section.title}
              </h2>
              <div className="space-y-4">
                {section.items.map((item, itemIndex) => (
                  <motion.div 
                    key={itemIndex}
                    variants={itemVariants}
                    className="flex gap-3 items-center"
                  >
                    <i className="bx bx-chevron-right text-blue-500 dark:text-blue-400 text-2xl mt-1"></i>
                    <span className="text-gray-700 dark:text-gray-300">
                      {`${index + 1}.${itemIndex + 1}. ${item}`}
                    </span>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
    </>    
  );
}

export default TermsOfService;