import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { useLanguage } from '../../contexts/LanguageContext';

const Footer = () => {
  const { theme } = useTheme();
  const { t } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const isHomePage = location.pathname === '/';

  // Обработчик для перехода к блоку pricing
  const handlePricingClick = (e) => {
    e.preventDefault();
    if (isHomePage) {
      // Если мы на главной, просто скроллим к секции
      document.querySelector('#pricing')?.scrollIntoView({ behavior: 'smooth' });
    } else {
      // Если на другой странице, переходим на главную со state
      navigate('/', { state: { scrollTo: 'pricing' } });
    }
  };

  // Эффект для обработки скролла после навигации
  useEffect(() => {
    if (location.state?.scrollTo === 'pricing') {
      setTimeout(() => {
        document.querySelector('#pricing')?.scrollIntoView({ behavior: 'smooth' });
        navigate(location.pathname, { replace: true, state: {} });
      }, 100);
    }
  }, [location.state]);

  const footerSections = [
    {
      title: t('services'),
      links: [
        { 
          text: t('vps.hosting'), 
          href: '/#pricing',
          onClick: handlePricingClick 
        },
      ]
    },
    {
      title: t('company'),
      links: [
        { text: t('referral.program'), to: '/referral-program' },
        { text: t('contacts'), to: '/contact' }
      ]
    },
    {
      title: t('support'),
      links: [
        { text: t('help.center'), href: 'https://t.me/nobuxe_supbot' },
      ]
    },
    {
      title: t('documents'),
      links: [
        { text: t('terms.of.use'), to: '/terms' },
        { text: t('privacy.policy'), to: '/privacy' },
        { text: t('recurring.info'), to: '/recurring' },
      ]
    }
  ];

  const socialLinks = [
    { icon: 'bxl-telegram', href: '#', label: 'Telegram' },
    { icon: 'bxl-discord', href: '#', label: 'Discord' },
    { icon: 'bxl-vk', href: '#', label: 'VK' },
    { icon: 'bxl-github', href: '#', label: 'GitHub' }
  ];

  const itemAnimation = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  // Функция для отрисовки ссылки с правильной обработкой клика
  const renderLink = (link) => {
    if (link.to) {
      return (
        <Link
          to={link.to}
          className="text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
        >
          {link.text}
        </Link>
      );
    }

    return (
      <a
        href={link.href}
        onClick={link.onClick}
        className="text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
      >
        {link.text}
      </a>
    );
  };

  return (
    <footer className="bg-gray-50 dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
      <div className="container mx-auto px-4">
        <div className="py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
            {/* Company Info */}
            <motion.div
              variants={itemAnimation}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
              className="lg:col-span-1"
            >
              <Link to="/" className="flex items-center space-x-2 mb-6">
                <img 
                  src="/logo.svg" 
                  alt="Nobuxe" 
                  className="h-8 w-auto" 
                  style={{ 
                    filter: theme === 'dark' ? 'brightness(0) saturate(100%) invert(1)' : 'brightness(0) saturate(100%)'
                  }}
                />
                <span className="text-xl font-bold text-gray-900 dark:text-white">
                  Nobuxe
                </span>
              </Link>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                {t('company.description')}
              </p>
              <div className="space-y-3">
                <a href="mailto:support@nobuxe.com" 
                   className="flex items-center text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400">
                  <i className="bx bx-envelope mr-2"></i>
                  support@nobuxe.com
                </a>
                <div className="text-sm text-gray-600 dark:text-gray-400 space-y-1">
                  <p>ИП Никифоряк Кирилл Александрович</p>
                  <p>ИНН: 246520031568</p>
                  <p>ОГРНИП: 324246800122191</p>
                </div>
              </div>
            </motion.div>

            {/* Navigation Links */}
            {footerSections.map((section) => (
              <motion.div
                key={section.title}
                variants={itemAnimation}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
                className="lg:col-span-1"
              >
                <h3 className="text-gray-900 dark:text-white font-semibold mb-4">
                  {section.title}
                </h3>
                <ul className="space-y-3">
                  {section.links.map((link) => (
                    <li key={link.text}>
                      {renderLink(link)}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="py-6 border-t border-gray-200 dark:border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            {/* Copyright */}
            <div className="text-gray-600 dark:text-gray-400 text-sm">
              © {currentYear} Nobuxe Hosting. {t('all.rights.reserved')}
            </div>

            {/* Payment Methods */}
            <div className="flex items-center space-x-4">
              <span className="text-gray-600 dark:text-gray-400 text-sm">
                {t('we.accept')}
              </span>
              <div className="flex items-center space-x-2">
                <i className="bx bxl-visa text-2xl text-gray-600 dark:text-gray-400"></i>
                <i className="bx bxl-mastercard text-2xl text-gray-600 dark:text-gray-400"></i>
                <i className="bx bx-wallet text-2xl text-gray-600 dark:text-gray-400"></i>
              </div>
            </div>

            {/* Social Links */}
            <div className="flex items-center space-x-4">
              {socialLinks.map((social) => (
                <motion.a
                  key={social.label}
                  href={social.href}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  aria-label={social.label}
                  className="text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
                >
                  <i className={`bx ${social.icon} text-2xl`}></i>
                </motion.a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;