import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, useTheme } from './contexts/ThemeContext.jsx';
import { LanguageProvider } from './contexts/LanguageContext.jsx';
import ErrorBoundary from './components/ErrorBoundary';

// Layout Components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

// Home Components
import Hero from './components/home/Hero';
import Features from './components/home/Features';
import Pricing from './components/home/Pricing';
import Testimonials from './components/home/Testimonials';
import FAQ from './components/home/FAQ';

// Pages
import DeServers from './pages/DeServers';
import ReferralProgram from './pages/ReferralProgram';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { RecurringPaymentsPolicy } from './pages/RecurringAgreement';
import { TermsOfService } from './pages/TermsOfService';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import TelegramFeed from './pages/TelegramFeed';

const AppContent = () => {
  const { theme } = useTheme();

  return (
    <div className={theme}>
      <style>{`.grecaptcha-badge { visibility: hidden; }`}</style>
      
      <ErrorBoundary>
        <Helmet>
          <html lang="en" />
          <title>Nobuxe - Аренда VPS серверов | Виртуальные серверы VPS</title>
          <meta name="description" content="Аренда виртуальных серверов VPS. Быстрые скорости, защита от DDoS атак, моментальное развертывание." />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          
          {/* Open Graph tags */}
          <meta property="og:title" content="Nobuxe - Аренда VPS серверов" />
          <meta property="og:description" content="Аренда виртуальных серверов VPS. Быстрые скорости, защита от DDoS атак, моментальное развертывание." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://nobuxe.com" />
          
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Nobuxe - Аренда VPS серверов" />
          <meta name="twitter:description" content="Аренда виртуальных серверов VPS. Быстрые скорости, защита от DDoS атак." />
          
          {/* Additional SEO tags */}
          <meta name="keywords" content="vps, vds, виртуальный сервер, аренда сервера, хостинг, защита от ddos" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://nobuxe.com" />
          
          {/* Additional meta tags */}
          <meta name="author" content="Nobuxe" />
          <meta name="theme-color" content="#000000" />
        </Helmet>

        <div className="flex flex-col min-h-screen dark:bg-gray-900">
          <Header />
          <main className="flex-grow">
            <Routes>
              {/* Home Route */}
              <Route 
                path="/" 
                element={
                  <>
                    <ErrorBoundary>
                      <Hero />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <Features />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <Pricing />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <Testimonials />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <FAQ />
                    </ErrorBoundary>
                  </>
                } 
              />

              {/* Server Locations */}
              <Route 
                path="/de-servers" 
                element={
                  <ErrorBoundary>
                    <DeServers />
                  </ErrorBoundary>
                } 
              />
              
              {/* Programs */}
              <Route 
                path="/referral-program" 
                element={
                  <ErrorBoundary>
                    <ReferralProgram />
                  </ErrorBoundary>
                } 
              />

              {/* About & Contact Pages */}
              <Route 
                path="/about" 
                element={
                  <ErrorBoundary>
                    <AboutPage />
                  </ErrorBoundary>
                } 
              />
              <Route 
                path="/contact" 
                element={
                  <ErrorBoundary>
                    <ContactPage />
                  </ErrorBoundary>
                } 
              />
              
              {/* News Feed */}
              <Route 
                path="/news" 
                element={
                  <ErrorBoundary>
                    <TelegramFeed />
                  </ErrorBoundary>
                } 
              />
              
              {/* Legal Pages */}
              <Route 
                path="/privacy" 
                element={
                  <ErrorBoundary>
                    <PrivacyPolicy />
                  </ErrorBoundary>
                } 
              />
              <Route 
                path="/recurring" 
                element={
                  <ErrorBoundary>
                    <RecurringPaymentsPolicy />
                  </ErrorBoundary>
                } 
              />
              <Route 
                path="/terms" 
                element={
                  <ErrorBoundary>
                    <TermsOfService />
                  </ErrorBoundary>
                } 
              />
            </Routes>
          </main>
          <Footer />
        </div>
      </ErrorBoundary>
    </div>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <LanguageProvider>
          <Router>
            <AppContent />
          </Router>
        </LanguageProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;