import React from 'react';
import { motion } from 'framer-motion';

const Card = ({ children, className = '', hover = false, ...props }) => {
  return (
    <motion.div
      whileHover={hover ? { y: -5 } : {}}
      className={`
        bg-white 
        dark:bg-gray-800 
        rounded-xl 
        shadow-sm
        transition-all
        ${className}
      `}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default Card;