import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { EmailInput } from "../ui/email-input";

const TRUSTED_COMPANIES = [
  { name: 'hetzner', logo: '/companies/hetzner.svg' },
  { name: 'ispsystem', logo: '/companies/ispsystem.svg' },
  { name: 'dataix', logo: '/companies/dataix.svg' },
  { name: 'datacamp', logo: '/companies/datacamp.svg' }
];

const RECAPTCHA_SITE_KEY = '6LfdpiMqAAAAABQbSy9hXnAcIg_eMmxZiiD1glx1';

const Hero = () => {
  const { t } = useLanguage();
  const recaptchaRef = useRef(null);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  
  const [formData, setFormData] = useState({
    email: '',
    errorMessage: '',
    loading: false,
    partner: '',
    registrationComplete: false,
    redirectUrl: ''
  });

  useEffect(() => {
    handlePartnerParam();
    
    // Определяем callback для загрузки reCAPTCHA
    window.onRecaptchaLoad = () => {
      console.log('reCAPTCHA loaded');
      setRecaptchaLoaded(true);
    };

    // Загружаем скрипт только если его еще нет
    if (!document.querySelector('script[src*="recaptcha"]')) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    } else if (window.grecaptcha) {
      // Если скрипт уже загружен
      setRecaptchaLoaded(true);
    }

    return () => {
      // Очистка
      const script = document.querySelector('script[src*="recaptcha"]');
      if (script) {
        document.head.removeChild(script);
      }
      delete window.onRecaptchaLoad;
    };
  }, []);

  const handlePartnerParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const refParam = urlParams.get('ref');
    const partner = refParam || localStorage.getItem('partner') || '';
    setFormData(prev => ({ ...prev, partner }));
    if (refParam) localStorage.setItem('partner', refParam);
  };

  const handleEmailChange = (e) => {
    setFormData(prev => ({ 
      ...prev, 
      email: e.target.value.trim(),
      errorMessage: '' 
    }));
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = formData.email.trim();
    
    if (!email) {
      setFormData(prev => ({ 
        ...prev, 
        errorMessage: t('showcase.emailRequired') 
      }));
      return;
    }
  
    if (!validateEmail(email)) {
      setFormData(prev => ({ 
        ...prev, 
        errorMessage: t('showcase.invalidEmail') 
      }));
      return;
    }

    if (!recaptchaLoaded || !window.grecaptcha) {
      setFormData(prev => ({ 
        ...prev, 
        errorMessage: t('showcase.recaptchaError') 
      }));
      return;
    }

    setFormData(prev => ({ ...prev, loading: true }));

    try {
      window.grecaptcha.execute();
    } catch (error) {
      console.error('reCAPTCHA execution error:', error);
      setFormData(prev => ({
        ...prev,
        loading: false,
        errorMessage: t('showcase.recaptchaError')
      }));
    }
  };

  // Глобальный callback для reCAPTCHA
  window.handleRecaptchaCallback = async (token) => {
    if (!token) {
      setFormData(prev => ({
        ...prev,
        loading: false,
        errorMessage: t('showcase.recaptchaError')
      }));
      return;
    }

    try {
      const response = await fetch('https://frontapi.nobuxe.com/api/register', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email: formData.email.trim(),
          locale: localStorage.getItem('locale') || 'en',
          partner: formData.partner,
          recaptcha: token
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || 'Network error');
      }
      
      const data = await response.json();

      setFormData(prev => ({
        ...prev,
        redirectUrl: data.redirect,
        registrationComplete: true,
        loading: false,
        errorMessage: ''
      }));
    } catch (error) {
      console.error('Registration error:', error);
      
      let errorMessage = t('showcase.registrationError');
      if (error.message.includes('email')) {
        errorMessage = t('showcase.invalidEmail');
      } else if (error.message.includes('recaptcha')) {
        errorMessage = t('showcase.recaptchaError');
      }

      setFormData(prev => ({ 
        ...prev, 
        errorMessage: errorMessage,
        loading: false 
      }));

      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
    }
  };

  window.handleRecaptchaError = () => {
    console.log('reCAPTCHA error callback triggered');
    setFormData(prev => ({
      ...prev,
      loading: false,
      errorMessage: t('showcase.recaptchaError')
    }));
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }
  };

  return (
    <div className="relative w-full min-h-screen bg-gradient-to-b from-gray-900 via-blue-950 to-gray-900">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-30" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent" />
        
        <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
      </div>

      <div className="relative w-full max-w-[1200px] mx-auto px-4 py-20">
        <div className="flex flex-col items-center mt-8">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center w-full max-w-[600px]"
          >
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-blue-200 tracking-tight mb-6">
              {t('hero.title')}
            </h1>
            
            <p className="text-lg md:text-xl text-blue-100 mb-4">
              {t('hero.subtitle')}
            </p>
            
            <p className="text-gray-400 text-base mb-8">
              {t('hero.description')}
            </p>

            <div className="w-full">
              {formData.registrationComplete ? (
                <SuccessPanel 
                  redirectUrl={formData.redirectUrl} 
                  t={t} 
                />
              ) : (
                <RegistrationForm 
                  formData={formData}
                  onEmailChange={handleEmailChange}
                  onSubmit={handleSubmit}
                  recaptchaLoaded={recaptchaLoaded}
                  t={t}
                />
              )}
              <div 
                className="g-recaptcha"
                data-sitekey={RECAPTCHA_SITE_KEY}
                data-size="invisible"
                data-callback="handleRecaptchaCallback"
                data-error-callback="handleRecaptchaError"
                ref={recaptchaRef}
              />
            </div>
          </motion.div>
        </div>

        <TrustedCompanies companies={TRUSTED_COMPANIES} t={t} />
      </div>
    </div>
  );
};

const RegistrationForm = ({ formData, onEmailChange, onSubmit, recaptchaLoaded, t }) => (
  <motion.form 
    onSubmit={onSubmit} 
    className="w-full bg-white/5 backdrop-blur-lg rounded-2xl p-6 border border-white/5"
  >
    <div className="mb-4">
      <EmailInput
        value={formData.email}
        onChange={onEmailChange}
        placeholder={t('showcase.emailPlaceholder')}
        disabled={formData.loading}
        required={true}
        type="email"
      />
    </div>
    <button
      className="w-full bg-blue-600 hover:bg-blue-500 text-white font-medium py-4 px-6 rounded-xl transition-all duration-300 disabled:opacity-50 shadow-lg shadow-blue-500/20"
      type="submit"
      disabled={formData.loading || !recaptchaLoaded}
    >
      {formData.loading ? (
        <LoadingSpinner text={t('showcase.loading')} />
      ) : (
        t('showcase.ctaButton')
      )}
    </button>
    {formData.errorMessage && (
      <p className="text-red-400 mt-3 text-sm text-center">
        {formData.errorMessage}
      </p>
    )}
  </motion.form>
);

const SuccessPanel = ({ redirectUrl, t }) => (
  <motion.div 
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10"
  >
    <p className="text-green-400 mb-6 text-xl font-medium">
      {t('showcase.registrationSuccess')}
    </p>
    <button
      onClick={() => window.location.href = redirectUrl}
      className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition"
    >
      {t('showcase.continueToAccount')}
    </button>
  </motion.div>
);

const LoadingSpinner = ({ text }) => (
  <span className="flex items-center justify-center">
    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </svg>
    {text}
  </span>
);

const TrustedCompanies = ({ companies, t }) => (
  <div className="mt-20 w-full">
    <h3 className="text-xl font-medium text-center text-white mb-2">
      {t('hero.trusted.title')}
    </h3>
    <p className="text-gray-400 mb-8 text-center text-sm">
      {t('hero.trusted.subtitle')}
    </p>
    
    <div className="flex flex-wrap justify-center gap-8">
      {companies.map((company, index) => (
        <div
          key={index}
          className="w-32 h-16 bg-white/5 rounded-lg p-3 transition-all duration-300 hover:bg-white/10"
        >
          <img
            src={company.logo}
            alt={`${company.name} logo`}
            className="w-full h-full object-contain opacity-70 hover:opacity-100 transition-opacity filter brightness-0 invert"
          />
        </div>
      ))}
    </div>
  </div>
);

export default Hero;