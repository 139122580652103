import React, { useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet-async';
import { m, motion } from 'framer-motion';

export const RecurringPaymentsPolicy = () => {
  const { t } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut'
      }
    }
  };

  const policyContent = {
    title: "Политика проведения рекуррентных платежей",
    intro: "Настоящая политика определяет порядок проведения регулярных автоматических платежей на сайте nobuxe.com. Данная политика регулирует отношения между Исполнителем и Клиентом при проведении рекуррентных платежей.",
    sections: [
      {
        title: "1. Определения",
        items: [
          "1.1. ИП Никифоряк Кирилл Александрович, ИНН 246520031568, ОГРНИП 324246800122191.",
          "1.2. Клиент -- физическое или юридическое лицо, которое зарегистрировалось в биллинговой системе Исполнителя.",
          "1.3. Основные определения устанавливаются в соответствие с Условиями использования, которые размещаются на сайте Исполнителя: https://nobuxe.com/terms"
        ]
      },
      {
        title: "2. Политика списания средств",
        items: [
          "2.1. Рекуррентные платежи являются возможными лишь в том случае, если Клиент при оплате счёта согласился с настоящей политикой рекуррентных платежей.",
          "2.2. Согласие с политикой рекуррентных платежей считается возможным в том случае, если Клиент при оплате выбрал опцию \"Сохранить способ оплаты\"",
          "2.3. Денежные средства списываются в соответствии с выбранным клиентом графиком платежей.",
          "2.4. График платежей устанавливается клиентом самостоятельно, но 1 (один) расчётный период не может быть менее 30 дней (в случае, если Исполнитель не указал иного в условиях тарифных планов).",
          "2.5. Также средства могут быть списаны автоматически, если после/во время заказа клиент указал, что необходимы автоматические списания по предложенным периодам.",
          "2.6. Периодичность и размер списания устанавливаются в зависимости от услуги и выбранной Клиентом периодичности при заказе через биллинг-систему, размещённой по адресу https://my.nobuxe.com/",
          "2.7. Соглашаясь с настоящей политикой, Клиент соглашается на ежемесячную подписку на услуги Исполнителя, оказываемые в соответствии с вышеуказанными Условиями использования."
        ]
      },
      {
        title: "3. Права и обязанности сторон",
        items: [
          "3.1. Исполнитель обязуется:",
          "3.1.1. Предоставлять услуги в соответствии с настоящими Условиями использования, которые размещены на веб-сайте Исполнителя в сети Интернет по адресу: https://nobuxe.com/terms",
          "3.1.2. Своевременно производить попытки проведения рекуррентных платежей во избежание приостановок услуг Клиента.",
          "3.1.3. Своевременно рассматривать и отвечать на претензии Клиента, в случае их возникновения.",
          "3.1.4. Предоставлять Клиенту полную и достоверную информацию обо всех рекуррентных списаниях.",
          "3.2. Клиент обязуется:",
          "3.2.1. Своевременно актуализировать платёжную информацию в биллинговой системе Исполнителя.",
          "3.2.2. Не использовать для проведения рекуррентных платежей недостоверные или чужие платёжные данные.",
          "3.2.3. Самостоятельно соблюдать законодательство Российской Федерации.",
          "3.2.4. Соблюдать Условия использования Исполнителя и не нарушать их условия.",
          "3.2.5. Самостоятельно отслеживать автоплатежи и отключать те, которые Клиенту в данный момент необходимы.",
          "3.2.6. Самостоятельно нести ответственность за все ошибочные списания по вине Клиента.",
          "3.2.7. Своевременно актуализировать и сообщать Исполнителю актуальную информацию о необходимых автоматических списаниях.",
          "3.2.8. Возместить убытки исполнителю, в том числе в рекуррентном порядке, в случае если в соответствии с Условиями Использования Исполнителю были нанесены существенные убытки по вине Клиента.",
          "3.3. Права исполнителя:",
          "3.3.1. Вернуть ошибочный платёж, в случае если с момента платежа не прошло более трёх дней.",
          "3.3.2. Переучесть ошибочный платёж в счёт другой услуги Клиента.",
          "3.3.3. Исполнитель имеет право прекратить рекуррентные платежи в случае нарушения Клиентом Условий использования.",
          "3.3.4. Исполнитель имеет право прекратить проведение рекуррентных платежей в одностороннем порядке.",
          "3.4. Права клиента:",
          "3.4.1. Клиент имеет право отслеживать список списаний в биллинговой системе Исполнителя.",
          "3.4.2. Клиент имеет право отказаться от рекуррентных списаний в одностороннем порядке."
        ]
      },
      {
        title: "4. Ответственность сторон",
        items: [
          "4.1. Клиент несёт полную и безоговорочную ответственность за соблюдение Условий использования и настоящей Политики проведения рекуррентных платежей.",
          "4.2. Клиент несёт полную и безоговорочную ответственность за соблюдение законодательства Российской Федерации.",
          "4.3. Клиент несёт ответственность за актуализацию и информирование Исполнителя о достоверности текущей платёжной информации.",
          "4.4. Клиент не вправе использовать чужие платёжные данные при проведении рекуррентных платежей.",
          "4.5. Исполнитель обязуется исполнять все возложенные на него Условиями использования и законодательством Российской Федерации обязанности.",
          "4.6. Исполнитель обязуется информировать с помощью собственного веб-сайта, информационных ресурсов и/или иных способов, не указанных в настоящей политике Клиента о предстоящих списаниях.",
          "4.7. Исполнитель обязуется своевременно обрабатывать претензии об ошибочных платежах.",
          "4.8. Исполнитель обязуется своевременно обрабатывать запросы клиентов об отказе от рекуррентных платежей.",
          "4.9. Исполнитель соглашается с тем, что его платёжную информацию будет хранить и обрабатывать Банк/Процессинговый центр/Платёжный агрегатор в целях проведения рекуррентных платежей и в соответствии со своими политиками.",
          "4.10. Исполнитель не хранит и не обрабатывает платёжную информацию напрямую, а лишь сохраняет информацию об актуальности текущих платёжных данных."
        ]
      },
      {
        title: "5. Возврат денежных средств",
        items: [
          "5.1. Клиент в праве вернуть рекуррентный платёж за вычетом фактически понесённых Исполнителем расходов при оказании услуги либо Исполнитель обязуется произвести перерасчёт стоимости услуги с вычетом из месячной стоимости платы за количество дней, сколько оказывалась услуга.",
          "5.2. Возврат денежных средств происходит либо путём отмены платежа в интернет-эквайринге Исполнителя, либо с помощью банковского перевода с расчётного счёта Исполнителя.",
          "5.2.1. Также Исполнитель вправе использовать иной способ возврата средств при взаимной согласии со стороны клиента.",
          "5.3. Возврат средств происходит в течении 14-и календарных дней с момента начала процедуры возврата со стороны Исполнителя.",
          "5.4. Порядок и условия возврата средств регулируются Условиями использования, размещёнными на веб-сайте Исполнителя по адресу в сети Интернет: https://nobuxe.com/terms"
        ]
      },
      {
        title: "6. Отмена рекуррентных (периодических) платежей",
        items: [
          "6.1. Клиент имеет право отказаться от рекуррентных платежей в одностороннем порядке с помощью биллинг-системы Исполнителя, размещённой по адресу: https://my.nobuxe.com/"
        ]
      },
      {
        title: "7. Порядок рассмотрения претензий",
        items: [
          "7.1. Все претензии должны быть направлены Клиентом на электронную почту Исполнителя по адресу: support@nobuxe.com",
          "7.2. Претензии рассматриваются в срок до 7-и рабочих дней с момента получения претензии."
        ]
      },
      {
        title: "Реквизиты организации",
        items: [
          "Наименование: ИП Никифоряк Кирилл Александрович",
          "ИНН: 246520031568",
          "ОГРНИП: 324246800122191",
          "БИК: 044525974",
          "Наименование банка: АО «ТБанк»",
          "Корр. счёт: 30101810145250000974",
          "Расчётный счёт: 40802810400006673681",
          "Адрес электронной почты: support@nobuxe.com"
        ]
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>Политика рекуррентных платежей | Nobuxe - Автоматические платежи</title>
        <meta 
          name="description" 
          content="Политика проведения рекуррентных платежей Nobuxe. Узнайте о правилах и условиях автоматических списаний за услуги хостинга." 
        />
        <meta 
          name="keywords" 
          content="рекуррентные платежи, автоплатежи, подписка, условия оплаты, хостинг, nobuxe" 
        />
        {/* Open Graph */}
        <meta 
          property="og:title" 
          content="Политика рекуррентных платежей Nobuxe" 
        />
        <meta 
          property="og:description" 
          content="Правила проведения автоматических платежей, порядок списаний и условия возврата средств в Nobuxe." 
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nobuxe.com/recurring" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta 
          name="twitter:title" 
          content="Политика рекуррентных платежей Nobuxe" 
        />
        <meta 
          name="twitter:description" 
          content="Правила проведения автоматических платежей, порядок списаний и условия возврата средств в Nobuxe." 
        />
        {/* Canonical URL */}
        <link rel="canonical" href="https://nobuxe.com/recurring" />
      </Helmet>

    <section className="relative w-full min-h-screen bg-gradient-to-b from-gray-50 via-white to-gray-50 dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
      {/* Background effects */}
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-5 dark:opacity-30" />
        
        {/* Gradient orbs */}
        <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
      </div>

      <div className="relative container mx-auto px-4 py-24">
        {/* Title */}
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-blue-800 to-blue-900 dark:from-white dark:via-blue-100 dark:to-blue-200"
        >
          {policyContent.title}
        </motion.h1>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Introduction */}
          <motion.div 
            variants={itemVariants}
            className="bg-white/80 dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 mb-8 shadow-xl dark:shadow-none"
          >
            <p className="text-gray-700 dark:text-gray-300">
              {policyContent.intro}
            </p>
          </motion.div>

{/* Sections */}
{policyContent.sections.map((section, index) => (
            <motion.div 
              key={index}
              variants={itemVariants}
              className="bg-white/80 dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 mb-8 shadow-xl dark:shadow-none"
            >
              <h2 className="text-2xl text-blue-500 dark:text-blue-400 mb-6">
                {section.title}
              </h2>
              <div className="space-y-4">
                {section.items.map((item, itemIndex) => (
                  <motion.div 
                    key={itemIndex}
                    variants={itemVariants}
                    className="flex gap-3 items-center"
                  >
                    {!section.title.includes('Реквизиты') && (
                      <i className="bx bx-chevron-right text-blue-500 dark:text-blue-400 text-2xl flex-shrink-0 mt-1"></i>
                    )}
                    <span className="text-gray-700 dark:text-gray-300 flex-1">
                      {item}
                    </span>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
    </>    
  );
}

export default RecurringPaymentsPolicy;