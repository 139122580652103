import React, { useState, useMemo, memo, useEffect } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet-async';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

const FrankfurtDescription = () => {
  const { t } = useLanguage();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="relative overflow-hidden rounded-2xl shadow-xl min-h-[300px]">
      {/* Background image with overlay */}
      <div className="absolute inset-0 z-0">
        <img 
          src="https://www.new-walls.com/media/f8/97/af/1687641431/78d33a97c3f2cdb78b5c94addd575477.jpg" 
          alt="Frankfurt Skyline"
          className="w-full h-full object-cover opacity-30 dark:opacity-30"
        />
        <div className="absolute inset-0 bg-blue-100/90 dark:bg-blue-900/90 backdrop-blur-sm" />
      </div>

      {/* Анимированный контейнер для контента */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="relative z-10 p-8"
      >
        {/* Decorative elements */}
        <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500/5 dark:bg-blue-500/10 rounded-full blur-3xl -mr-32 -mt-32" />
        <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-300/5 dark:bg-blue-300/10 rounded-full blur-3xl -ml-32 -mb-32" />
        
        {/* City Icon */}
        <div className="relative mb-6">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 100 }}
            className="w-16 h-16 bg-blue-500/10 dark:bg-blue-500/20 rounded-2xl backdrop-blur-sm flex items-center justify-center mb-4"
          >
            <i className="bx bxs-city text-3xl text-blue-600 dark:text-blue-300" />
          </motion.div>
        </div>

        {/* Main content */}
        <div className="relative space-y-4">
          <motion.h2 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="text-2xl font-bold text-gray-900 dark:text-white mb-4"
          >
            {t('locations.de-fra')}
          </motion.h2>
          
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="text-gray-700 dark:text-blue-100/90 leading-relaxed"
          >
            {t('locations.de-fra.city.description')}
          </motion.p>

          {/* Stats */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="grid grid-cols-2 gap-4 mt-8 pt-8 border-t border-gray-200/20 dark:border-blue-400/20"
          >
            <div className="text-center">
              <div className="text-3xl font-bold text-blue-600 dark:text-blue-300 mb-2">
                {t('locations.de-fra.stats.rank')}
              </div>
              <div className="text-sm text-gray-600 dark:text-blue-200/80">
                {t('locations.de-fra.stats.rank.description')}
              </div>
            </div>
            <div className="text-center">
              <div className="text-3xl font-bold text-blue-600 dark:text-blue-300 mb-2">
                &lt;50ms
              </div>
              <div className="text-sm text-gray-600 dark:text-blue-200/80">
                {t('locations.de-fra.stats.latency')}
              </div>
            </div>
          </motion.div>
        </div>

        {/* Corner decorations */}
        <div className="absolute top-4 right-4 w-8 h-8 border-t-2 border-r-2 border-gray-400/30 dark:border-blue-400/30 rounded-tr-lg" />
        <div className="absolute bottom-4 left-4 w-8 h-8 border-b-2 border-l-2 border-gray-400/30 dark:border-blue-400/30 rounded-bl-lg" />
      </motion.div>
    </div>
  );
};

const ServerSpecItem = memo(({ icon, text, index }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: index * 0.1, duration: 0.3 }}
    className="flex items-center space-x-3 text-gray-700 dark:text-gray-300 min-h-[24px]"
  >
    <i className={`bx ${icon} text-lg text-blue-500 w-5`}></i>
    <span className="flex-1">{text}</span>
  </motion.div>
));

const PriceDisplay = memo(({ price, t }) => (
  <motion.div
    layout
    className="flex items-baseline justify-center h-14"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
  >
    <span className="text-sm mr-1 text-gray-900 dark:text-gray-100">€</span>
    <span className="text-4xl font-bold text-gray-900 dark:text-gray-100">{price}</span>
    <span className="text-gray-500 dark:text-gray-400 ml-2 min-w-[40px]">/ {t('pricing.month.short')}</span>
  </motion.div>
));

const LocationFeature = memo(({ icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    whileHover={{ y: -8, transition: { duration: 0.3 } }}
    className="group relative h-full"
  >
    <div className="relative z-10 flex flex-col items-center text-center p-8 rounded-2xl bg-gradient-to-b from-white/90 to-white/80 dark:from-blue-950/50 dark:to-blue-900/30 backdrop-blur-lg border border-gray-200/50 dark:border-white/10 shadow-xl h-full">
      {/* Glow effect */}
      <div className="absolute inset-0 rounded-2xl bg-blue-500/5 group-hover:bg-blue-500/10 transition-colors duration-300" />
      
      {/* Icon container with glow */}
      <div className="relative mb-6">
        <div className="absolute inset-0 bg-blue-500/20 rounded-full blur-xl group-hover:blur-2xl transition-all duration-300" />
        <div className="relative w-16 h-16 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center shadow-lg group-hover:shadow-blue-500/25 transition-shadow duration-300">
          <i className={`bx ${icon} text-3xl text-white`} />
        </div>
      </div>

      <div className="flex flex-col flex-grow">
        <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-3 group-hover:text-blue-600 dark:group-hover:text-blue-300 transition-colors duration-300">
          {title}
        </h3>
        <p className="text-gray-600 dark:text-gray-300 leading-relaxed flex-grow">
          {description}
        </p>
      </div>

      <div className="absolute -top-2 -left-2 w-6 h-6 border-t-2 border-l-2 border-blue-500/30 rounded-tl-lg" />
      <div className="absolute -bottom-2 -right-2 w-6 h-6 border-b-2 border-r-2 border-blue-500/30 rounded-br-lg" />
    </div>
  </motion.div>
));

const LocationInfo = ({ t }) => (
  <div className="py-20 px-4 bg-white dark:bg-gradient-to-b dark:from-blue-950 dark:to-blue-900">
    <div className="container mx-auto">
      <div className="mb-16">
        <FrankfurtDescription />
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <LocationFeature
            icon="bxs-server"
            title={t('locations.de-fra.features.datacenter.title')}
            description={t('locations.de-fra.features.datacenter.description')}
          />
          <LocationFeature
            icon="bxs-network-chart"
            title={t('locations.de-fra.features.network.title')}
            description={t('locations.de-fra.features.network.description')}
          />
          <LocationFeature
            icon="bxs-shield-alt-2"
            title={t('locations.de-fra.features.security.title')}
            description={t('locations.de-fra.features.security.description')}
          />
          <LocationFeature
            icon="bxs-bolt"
            title={t('locations.de-fra.features.power.title')}
            description={t('locations.de-fra.features.power.description')}
          />
        </div>
      </div>
    </div>
  </div>
);

const DeServers = () => {
  const { t } = useLanguage();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const servers = useMemo(() => [
    {
      name: 'DE-Ryzen-0',
      monthly: 2.99,
      yearly: 35.88,
      specs: {
        cpu: '1 vCPU AMD Ryzen 9 5950X',
        ram: '2 GB RAM',
        storage: '20 GB NVMe',
      },
      totalUsers: 2854
    },
    {
      name: 'DE-Ryzen-1',
      monthly: 4.69,
      yearly: 56.28,
      specs: {
        cpu: '2 vCPU AMD Ryzen 9 5950X',
        ram: '4 GB RAM',
        storage: '40 GB NVMe',
      },
      isPopular: true,
      totalUsers: 3102
    },
    {
      name: 'DE-Ryzen-2',
      monthly: 8.69,
      yearly: 104.28,
      specs: {
        cpu: '4 vCPU AMD Ryzen 9 5950X',
        ram: '8 GB RAM',
        storage: '80 GB NVMe',
      },
      totalUsers: 2945
    },
    {
      name: 'DE-Ryzen-3',
      monthly: 9.99,
      yearly: 119.88,
      specs: {
        cpu: '6 vCPU AMD Ryzen 9 5950X',
        ram: '16 GB RAM',
        storage: '160 GB NVMe',
      },
      totalUsers: 1876
    },
    {
      name: 'DE-Ryzen-4',
      monthly: 15.99,
      yearly: 191.88,
      specs: {
        cpu: '8 vCPU AMD Ryzen 9 5950X',
        ram: '32 GB RAM',
        storage: '320 GB NVMe',
      },
      totalUsers: 1243
    }
  ], []);

  const commonFeatures = useMemo(() => [
    { icon: 'bx-transfer-alt', text: t('pricing.features.traffic') },
    { icon: 'bx-shield-quarter', text: t('pricing.features.ddos') },
    { icon: 'bx-support', text: t('pricing.features.support') },
    { icon: 'bx-timer', text: t('pricing.features.uptime') },
    { icon: 'bx-line-chart', text: t('pricing.features.monitoring') }
  ], [t]);

  const sortedServers = useMemo(() => {
    if (!sortConfig.key) return servers;

    return [...servers].sort((a, b) => {
      let aValue = sortConfig.key === 'price' ? a.monthly : a[sortConfig.key];
      let bValue = sortConfig.key === 'price' ? b.monthly : b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [servers, sortConfig]);

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.08
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        delay: index * 0.1,
        ease: 'easeOut'
      }
    }),
    hover: {
      y: -8,
      transition: {
        duration: 0.3,
        ease: 'easeOut'
      }
    }
  };

  return (
    <LayoutGroup>
      <Helmet>
        <title>Тарифы DE серверов | Nobuxe</title>
        <meta name="description" content="Выберите оптимальный тариф виртуального сервера для ваших задач. Мощные процессоры AMD Ryzen, быстрые NVMe диски и защита от DDoS." />
        <meta property="og:title" content="Тарифы виртуальных серверов | Nobuxe" />
        <meta property="og:description" content="Выберите оптимальный тариф виртуального сервера для ваших задач. Мощные процессоры AMD Ryzen, быстрые NVMe диски и защита от DDoS." />
        <meta name="twitter:title" content="Тарифы виртуальных серверов | Nobuxe" />
        <meta name="twitter:description" content="Выберите оптимальный тариф виртуального сервера для ваших задач. Мощные процессоры AMD Ryzen, быстрые NVMe диски и защита от DDoS." />
      </Helmet>

      <section className="relative bg-gradient-to-b from-gray-50 via-white to-gray-50 dark:from-gray-900 dark:via-blue-950 dark:to-gray-900 pt-20">
        <LocationInfo t={t} />
        <div className="relative container mx-auto px-4 py-20">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="text-center max-w-3xl mx-auto mb-12"
          >
            <h1 className="text-4xl font-bold mb-6 text-gray-900 dark:text-white">
              {t('pricing.title')}
            </h1>
            <p className="text-gray-600 dark:text-gray-400">
              {t('pricing.description')}
            </p>
          </motion.div>

          <div className="w-full flex justify-center mb-8">
            <div className="flex gap-4 bg-white dark:bg-white/10 backdrop-blur-lg rounded-xl border border-gray-200 dark:border-white/10 p-3">
              <Button
                variant="ghost"
                onClick={() => handleSort('price')}
                className={`
                  flex items-center justify-center gap-2 px-4 h-12 rounded-lg
                  hover:bg-gray-50 dark:hover:bg-white/5 transition-colors
                  ${sortConfig.key === 'price' 
                    ? 'text-blue-600 dark:text-blue-400' 
                    : 'text-gray-600 dark:text-gray-300'}
                `}
              >
                <i className="bx bx-euro text-xl" />
                <span>{t('price')}</span>
                <i className={`bx ${
                  sortConfig.key === 'price' 
                    ? sortConfig.direction === 'asc' 
                      ? 'bx-sort-up' 
                      : 'bx-sort-down'
                    : 'bx-sort'
                } text-xl`} />
              </Button>
              <Button
                variant="ghost"
                onClick={() => handleSort('totalUsers')}
                className={`
                  flex items-center justify-center gap-2 px-4 h-12 rounded-lg
                  hover:bg-gray-50 dark:hover:bg-white/5 transition-colors
                  ${sortConfig.key === 'totalUsers' 
                    ? 'text-blue-600 dark:text-blue-400' 
                    : 'text-gray-600 dark:text-gray-300'}
                `}
              >
                <i className="bx bx-user text-xl" />
                <span>{t('users')}</span>
                <i className={`bx ${
                  sortConfig.key === 'totalUsers' 
                    ? sortConfig.direction === 'asc' 
                      ? 'bx-sort-up' 
                      : 'bx-sort-down'
                    : 'bx-sort'
                } text-xl`} />
              </Button>
            </div>
          </div>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6"
          >
            <AnimatePresence mode="wait">
              {sortedServers.map((server, index) => (
                <motion.div
                  key={server.name}
                  custom={index}
                  variants={cardVariants}
                  whileHover="hover"
                >
                  <Card 
                    className={`h-full bg-white dark:bg-white/5 backdrop-blur-lg ${
                      server.isPopular 
                        ? 'border-2 border-blue-500 dark:border-blue-400 relative shadow-lg' 
                        : 'border border-gray-200 dark:border-white/10'
                    }`}
                  >
                    {server.isPopular && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="absolute -top-3 left-0 right-0 flex justify-center h-8"
                      >
                        <span className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                          {t('pricing.popular.choice')}
                        </span>
                      </motion.div>
                    )}

                    <div className="p-6">
                      <motion.div layout className="text-center mb-8 min-h-[160px]">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-1 min-h-[28px]">
                          {server.name}
                        </h3>
                        <div className="flex items-center justify-center text-sm font-medium text-gray-500 dark:text-gray-400 mb-4 min-h-[3rem]">
                          <i className="bx bx-map-pin mr-1 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                          <span className="text-center">
                            {t('locations.de-fra')}
                          </span>
                        </div>
                        <AnimatePresence mode="wait">
                          <PriceDisplay 
                            price={server.monthly}
                            t={t}
                          />
                        </AnimatePresence>
                        <div className="text-sm text-gray-500 dark:text-gray-400 mt-2 h-6">
                          <div className="flex items-center justify-center gap-1">
                            <i className="bx bx-user text-lg" />
                            {server.totalUsers.toLocaleString()} {t('pricing.active.clients')}
                          </div>
                        </div>
                      </motion.div>

                      <div className="space-y-6">
                        <div className="space-y-3 border-b border-gray-200 dark:border-white/10 pb-4 min-h-[180px]">
                          <h4 className="font-medium text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-3 h-5">
                            {t('pricing.specs')}
                          </h4>
                          <div className="space-y-3">
                            {Object.entries(server.specs).map(([key, value], idx) => (
                              <ServerSpecItem 
                                key={key}
                                icon={key === 'cpu' ? 'bxs-chip' : key === 'ram' ? 'bxs-memory-card' : 'bxs-hdd'}
                                text={value}
                                index={idx}
                              />
                            ))}
                          </div>
                        </div>

                        <div className="space-y-3 min-h-[280px]">
                          <h4 className="font-medium text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-3 h-5">
                            {t('pricing.included')}
                          </h4>
                          {commonFeatures.map((feature, idx) => (
                            <ServerSpecItem 
                              key={idx}
                              icon={feature.icon}
                              text={feature.text}
                              index={idx}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="mt-8 h-12">
                        <Button
                          variant={server.isPopular ? 'primary' : 'outline'}
                          className={`w-full ${
                            server.isPopular 
                              ? 'bg-blue-500 hover:bg-blue-600 text-white' 
                              : 'border-gray-200 hover:bg-gray-50 dark:border-white/10 dark:hover:bg-white/10'
                          }`}
                          onClick={() => window.location.href = 'https://my.nobuxe.com/'}
                        >
                          {t('pricing.select.plan')}
                        </Button>
                      </div>
                    </div>
                  </Card>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4 }}
            className="mt-16 text-center min-h-[120px]"
          >
            <p className="text-gray-600 dark:text-gray-400 mb-4 min-h-[24px]">
              {t('pricing.custom.plan')}
            </p>
            <Button 
              variant="outline"
              className="border-gray-200 hover:bg-gray-50 dark:border-white/10 dark:hover:bg-white/10"
              onClick={() => window.location.href = 'https://t.me/nobuxe_supbot'}
            >
              {t('pricing.contact.us')}
            </Button>
          </motion.div>
        </div>
      </section>
    </LayoutGroup>
  );
};

export default DeServers;