import React, { createContext, useContext, useState } from 'react';

const translations = {
  ru: {
    // Существующие переводы
    'virtual.servers': 'Виртуальные серверы',
    'germany': 'Германия',
    'prices': 'Цены',
    'about': 'О нас',
    'contacts': 'Контакты',
    'referral.program': 'Реферальная программа',
    'go.to.panel': 'Перейти к панели',

    // Футер - Заголовки секций
    'services': 'Услуги',
    'company': 'Компания',
    'support': 'Поддержка',
    'documents': 'Документы',

    // Футер - Услуги
    'vps.hosting': 'VPS хостинг',
    'dedicated.servers': 'Выделенные серверы',
    'ddos.protection': 'Защита от DDoS',
    'administration': 'Администрирование',
    'backup': 'Резервное копирование',

    // Футер - Компания
    'jobs': 'Вакансии',
    'blog': 'Блог',
    'partner.program': 'Партнерская программа',

    // Футер - Поддержка
    'help.center': 'Центр помощи',
    'documentation': 'Документация',
    'server.status': 'Статус серверов',
    'api': 'API',
    'knowledge.base': 'База знаний',

    // Футер - Документы
    'terms.of.use': 'Условия использования',
    'privacy.policy': 'Политика конфиденциальности',
    'recurring.info': 'Соглашение о рекуррентных платежах',
    'gdpr': 'GDPR',
    'requisites': 'Реквизиты',

    // Футер - Описание и контакты
    'company.description': 'Профессиональный хостинг для вашего бизнеса с высокой производительностью и надежностью.',
    'all.rights.reserved': 'Все права защищены.',
    'we.accept': 'Мы принимаем:',
    // FAQ Section
    'faq.title': 'Часто задаваемые вопросы',
    'faq.description': 'Ответы на самые распространенные вопросы о нашем хостинге. Если вы не нашли ответ на свой вопрос, обратитесь в службу поддержки.',
    
    // FAQ Questions
    'faq.how.to.start.question': 'Как начать использовать ваш хостинг?',
    'faq.how.to.start.answer': `Начать работу с нашим хостингом очень просто:
      1. Зарегистрируйтесь на сайте
      2. Выберите подходящий тарифный план
      3. Оплатите услугу удобным способом
      4. Получите доступ к панели управления
      5. Настройте свой сервер и начните работу
      
      Если у вас возникнут вопросы, наша служба поддержки доступна 24/7.`,
    
    'faq.virtualization.question': 'Какую виртуализацию вы используете на серверах?',
    'faq.virtualization.answer': 'Мы используем KVM виртуализацию, которая обеспечивает полную изоляцию ресурсов и высокую производительность. Каждый виртуальный сервер работает независимо и имеет гарантированные ресурсы процессора, памяти и дискового пространства.',
    
    'faq.change.plan.question': 'Можно ли изменить тариф после покупки?',
    'faq.change.plan.answer': 'Да, вы можете изменить тариф в любое время. При переходе на более высокий тариф производится перерасчет стоимости. Все изменения производятся без потери данных.',
    
    'faq.data.security.question': 'Как обеспечивается безопасность данных?',
    'faq.data.security.answer': 'Безопасность данных обеспечивается комплексом мер: использованием современных систем защиты от DDoS-атак, регулярным резервным копированием, мониторингом серверов 24/7, физической безопасностью дата-центров и соблюдением всех современных стандартов информационной безопасности.',
    
    'faq.ddos.protection.question': 'Какая защита от DDoS-атак предоставляется?',
    'faq.ddos.protection.answer': 'Мы предоставляем базовую защиту от DDoS-атак.',
    
    'faq.support.question': 'Как осуществляется техническая поддержка?',
    'faq.support.answer': 'Техническая поддержка доступна 24/7 через несколько каналов связи: тикет-система, онлайн-чат. Среднее время ответа составляет 15 минут. Наши специалисты помогут решить любые вопросы, связанные с работой серверов.',
    
    // FAQ Bottom Section
    'faq.no.answer.found': 'Не нашли ответ на свой вопрос?',
    'faq.support.available': 'Наша служба поддержки готова помочь вам 24/7. Среднее время ответа — 15 минут.',
    'faq.contact.support': 'Написать в поддержку',
    'faq.read.docs': 'Читать документацию',

    // Pricing Section
    'pricing.title': 'Тарифы и цены',
    'pricing.description': 'Выберите подходящий тариф для вашего проекта. Все тарифы включают полный набор функций и круглосуточную поддержку.',
    'pricing.billing.monthly': 'Помесячно',
    'pricing.billing.yearly': 'Годовой',
    'pricing.yearly.savings': 'Сэкономьте до 20% при годовой оплате',
    'pricing.popular.choice': 'Популярный выбор',
    'pricing.active.clients': 'активных клиентов',
    'pricing.month.short': 'мес',
    'pricing.year.short': 'год',
    'pricing.month.yearly.short': 'мес*',

    // Pricing Features
    'pricing.specs': 'Характеристики',
    'pricing.included': 'Включено',
    'pricing.features.traffic': 'Безлимитный трафик',
    'pricing.features.ddos': 'DDoS защита Level 3, 4',
    'pricing.features.support': 'Поддержка 24/7',
    'pricing.features.backup': 'Backup системы',
    'pricing.features.uptime': '99.9% uptime',
    'pricing.features.monitoring': 'Мониторинг',

    // Pricing Actions
    'pricing.select.plan': 'Выбрать тариф',
    'pricing.custom.plan': 'Нужен индивидуальный тариф?',
    'pricing.contact.us': 'Связаться с нами',  

    'locations.de-fra': 'Германия, Франкфурт-на-Майне',

    'price': 'Цена',
    'users': 'Пользователи',

    // Testimonials Section
    'testimonials.title': 'Отзывы клиентов',
    
    // Statistics
    'testimonials.stats.happy.clients': 'Довольных клиентов',
    'testimonials.stats.average.rating': 'Средняя оценка',
    'testimonials.stats.countries': 'Стран',
    'testimonials.stats.reviews': 'Отзывов',

    // Testimonials Data
    'testimonials.1.name': 'Александр Петров',
    'testimonials.1.position': 'CEO, TechStart',
    'testimonials.1.text': 'Перешли на Nobuxe после нескольких лет использования другого хостинга. Скорость работы серверов превзошла все ожидания. Техподдержка реагирует мгновенно, а цены остаются конкурентными.',
    
    'testimonials.2.name': 'Мария Иванова',
    'testimonials.2.position': 'CTO, DataFlow',
    'testimonials.2.text': 'Надежность на высшем уровне. За год использования не было ни одного существенного сбоя. Отдельно хочу отметить качество защиты от DDoS-атак – работает безупречно.',
    
    'testimonials.3.name': 'Дмитрий Сидоров',
    'testimonials.3.position': 'DevOps Engineer, CloudSys',
    'testimonials.3.text': 'Впечатляет уровень автоматизации и удобство панели управления. Масштабирование происходит в пару кликов, а производительность серверов позволяет справляться с любыми нагрузками.',   

    // Features Section
    'features.title': 'Наши преимущества',
    'features.description': 'Мы предоставляем надежные и высокопроизводительные решения для вашего бизнеса, обеспечивая безопасность и стабильность работы.',
    
    // Features Items
    'features.performance.title': 'Высокая производительность',
    'features.performance.description': 'Современное оборудование для высокой скорости работы',
    
    'features.reliability.title': 'Надежность',
    'features.reliability.description': '99.9% аптайм',
    
    'features.flexibility.title': 'Гибкость',
    'features.flexibility.description': 'Полная масштабируемость ресурсов',
    
    'features.security.title': 'Безопасность',
    'features.security.description': 'DDoS защита',
    
    // Features Call to Action
    'features.learn.more.question': 'Хотите узнать больше о наших возможностях?',
    'features.learn.more.button': 'Подробнее о технологиях',    

    // Hero Section
    'hero.title': 'Профессиональный хостинг для вашего бизнеса',
    'hero.subtitle': 'Надежные серверы для ваших проектов',
    'hero.description': 'Мы предоставляем высокопроизводительные виртуальные и выделенные серверы с защитой от DDoS-атак и круглосуточной поддержкой.',
    'hero.button.start': 'Начать',
    
    // Stats
    'hero.stats.servers': 'Серверов',
    'hero.stats.clients': 'Клиентов',
    'hero.stats.uptime': 'Аптайм',
    'hero.stats.locations': 'Локаций',
    
    // Server Status
    'hero.server.status.good': 'Все хорошо',
    'hero.server.status.description': 'Все системы работают в штатном режиме',
    
    // Trusted Section
    'hero.trusted.title': 'Что использует nobuxe для обеспечения качества?',
    'hero.trusted.subtitle': 'Ведущие вендоры и поставщики технологий',    

    // Referral Program Page
    'referral.title': 'Реферальная программа',
    'referral.earning.title': 'Зарабатывайте 5% с расходов ваших рефералов, без ограничений.',
    
    // How it works
    'referral.how.it.works': 'Как это работает',
    'referral.share.link': 'Поделитесь своей реферальной ссылкой',
    'referral.earn.percent': 'Получайте 5% с любых расходов приведенных клиентов',
    'referral.payments': 'Выплаты на банковский счет или в криптовалюте',
    
    // Partner Block
    'referral.partner.title': 'Мы — ваш надежный партнер',
    'referral.partner.description': 'Наши услуги работают стабильно и без сбоев.',
    
    // Rules
    'referral.rules.title': 'Правила программы',
    'referral.rules.no.spam': 'Спам и массовые рассылки запрещены',
    'referral.rules.no.impersonation': 'Запрещено выдавать себя за представителя Nobuxe',
    'referral.rules.payments.currency': 'Выплаты производятся в местной валюте',
    'referral.rules.cancellation': 'Nobuxe оставляет за собой право отменить выплаты при нарушении правил',    

    'showcase.title': 'Профессиональный хостинг для вашего бизнеса',
    'showcase.description': 'Надежные серверы с защитой от DDoS-атак и круглосуточной поддержкой',
    'showcase.emailPlaceholder': 'Введите ваш email',
    'showcase.ctaButton': 'Начать работу',
    'showcase.loading': 'Загрузка...',
    'showcase.waitingForRecaptcha': 'Подождите...',
    'showcase.emailRequired': 'Введите email',
    'showcase.invalidEmail': 'Некорректный email',
    'showcase.recaptchaNotReady': 'Пожалуйста, подождите загрузки reCAPTCHA',
    'showcase.registrationError': 'Ошибка при регистрации. Попробуйте позже',
    'showcase.registrationSuccess': 'Регистрация успешно завершена!',
    'showcase.continueToAccount': 'Перейти в личный кабинет',


    'locations.de-fra.features.datacenter.title': 'Сертифицированный дата-центр',
    'locations.de-fra.features.datacenter.description': 'Дата-центр во Франкфурте сертифицирован по ISO/IEC 27001, обеспечивая высочайшие стандарты безопасности',
    'locations.de-fra.features.network.title': 'Мощная сетевая инфраструктура',
    'locations.de-fra.features.network.description': 'Высокоскоростная сетевая инфраструктура с резервированием каналов связи и низкой задержкой',
    'locations.de-fra.features.security.title': 'Многоуровневая безопасность',
    'locations.de-fra.features.security.description': '24/7 охрана, система контроля доступа, видеонаблюдение и многофакторная аутентификация для персонала',
    'locations.de-fra.features.power.title': 'Надёжное энергоснабжение',
    'locations.de-fra.features.power.description': 'Система двойного электропитания с резервными дизель-генераторами и ИБП, обеспечивающая бесперебойную работу',
    'locations.de-fra.city.description': 'Франкфурт-на-Майне — крупнейший город земли Гессен и пятый по величине город Германии. Расположенный в самом сердце Европы, на берегах реки Майн, город славится своим историческим центром с характерной архитектурой и современными небоскребами делового района. Благодаря центральному расположению и развитой инфраструктуре, Франкфурт является идеальным местом для размещения серверов с минимальными задержками для всей Европы.',
    'locations.de-fra.stats.rank': '5-й',
    'locations.de-fra.stats.rank.description': 'по величине город Германии',
    'locations.de-fra.stats.latency': 'задержка до основных европейских городов',

    'about.title': "О компании Nobuxe",
    'about.subtitle': "Ваш надежный партнер в сфере виртуального хостинга с 2023 года",
    'about.our.story': "Наша История",
    'about.story.content': "Nobuxe начался с идеи сделать премиальный VPS хостинг доступным для каждого. Начав в 2023 году, мы выросли из небольшой команды энтузиастов в надежного хостинг-провайдера, обслуживающего клиентов по всему миру с передовой инфраструктурой и исключительной поддержкой.",
    'about.our.mission': "Наша Миссия",
    'about.mission.content': "Мы стремимся предоставлять хостинг-решения корпоративного уровня, сочетающие надежность, производительность и безопасность по конкурентным ценам. Наша цель - дать бизнесу и частным лицам инструменты, необходимые для успеха в цифровом мире.",

    'about.feature1.title': "Продвинутая Безопасность",
    'about.feature1.description': "Современная защита от DDoS-атак и меры безопасности для защиты ваших серверов 24/7",
    'about.feature2.title': "Поддержка 24/7",
    'about.feature2.description': "Профессиональная команда технической поддержки, готовая помочь вам в любое время",
    'about.feature3.title': "Высокая Производительность",
    'about.feature3.description': "Оборудование последнего поколения и оптимизированная инфраструктура для максимальной производительности",

    'about.our.team': "Наша Команда",
    'about.team.member1.name': "Алексей Смирнов",
    'about.team.member1.position': "Генеральный директор",
    'about.team.member2.name': "Мария Родригез",
    'about.team.member2.position': "Технический директор",
    'about.team.member3.name': "Давид Чен",
    'about.team.member3.position': "Руководитель поддержки",
    'about.team.member4.name': "София Андерсон",
    'about.team.member4.position': "Менеджер разработки",    

    'contact.title': "Связаться с нами",
    'contact.subtitle': "Есть вопросы? Мы здесь, чтобы помочь и готовы ответить на ваши запросы",

    'contact.info.title': "Контактная информация",
    'contact.hours': "Часы работы",
    'contact.hours.value': "Поддержка доступна 24/7",
    'contact.social': "Социальные сети",
    'contact.social.value': "Telegram, Discord",

    'contact.form.name': "Ваше имя",
    'contact.form.email': "Email адрес",
    'contact.form.subject': "Тема",
    'contact.form.message': "Ваше сообщение",
    'contact.form.send': "Отправить сообщение",
    'contact.form.sending': "Отправка...",
    'contact.form.success': "Сообщение успешно отправлено! Мы скоро свяжемся с вами.",
    'contact.form.error': "Произошла ошибка при отправке сообщения. Пожалуйста, попробуйте еще раз.",

    'contact.telegram.title': 'Telegram',
    'contact.telegram.description': 'Получите быструю поддержку через нашего Telegram-бота',
    'contact.telegram.button': 'Написать в Telegram',
    'contact.email.title': 'Email',
    'contact.email.description': 'Напишите нам на почту для детальных запросов',
    'contact.response.title': 'Время ответа',
    'contact.response.description': 'Мы отвечаем на все запросы в течение 24 часов. Для срочных вопросов используйте Telegram.',
    'contact.additional.info': 'Поддержка доступна с понедельника по пятницу, с 9:00 до 18:00 МСК',   

    'feed.title': 'Последние обновления',
    'feed.subtitle': 'Будьте в курсе наших новостей и объявлений',
    'feed.error.loading': 'Не удалось загрузить посты',
    'feed.error.generic': 'Ошибка при загрузке постов: ',
    'feed.no.posts': 'Нет доступных постов',
    'feed.goto.channel': 'Открыть Telegram канал',
    'feed.goto.post': 'Открыть пост',
    'feed.image.alt': 'Изображение поста',
    'feed.meta.title': 'Новости Nobuxe | Telegram-канал',
    'feed.meta.description': 'Актуальные обновления и новости сервиса Nobuxe. Следите за нашими последними обновлениями в Telegram.',
    'locale': 'ru-RU',
    'news': 'Новости',
  },
  en: {
    // Существующие переводы
    'virtual.servers': 'Virtual Servers',
    'germany': 'Germany',
    'prices': 'Prices',
    'about': 'About',
    'contacts': 'Contacts',
    'referral.program': 'Referral Program',
    'go.to.panel': 'Go to Panel',

    // Футер - Заголовки секций
    'services': 'Services',
    'company': 'Company',
    'support': 'Support',
    'documents': 'Documents',

    // Футер - Услуги
    'vps.hosting': 'VPS Hosting',
    'dedicated.servers': 'Dedicated Servers',
    'ddos.protection': 'DDoS Protection',
    'administration': 'Administration',
    'backup': 'Backup',

    // Футер - Компания
    'jobs': 'Careers',
    'blog': 'Blog',
    'partner.program': 'Partner Program',

    // Футер - Поддержка
    'help.center': 'Help Center',
    'documentation': 'Documentation',
    'server.status': 'Server Status',
    'api': 'API',
    'knowledge.base': 'Knowledge Base',

    // Футер - Документы
    'terms.of.use': 'Terms of Use',
    'privacy.policy': 'Privacy Policy',
    'recurring.info': 'Recurring payments agreement',
    'gdpr': 'GDPR',
    'requisites': 'Requisites',

    // Футер - Описание и контакты
    'company.description': 'Professional hosting for your business with high performance and reliability.',
    'all.rights.reserved': 'All rights reserved.',
    'we.accept': 'We accept:',

    // FAQ Section
    'faq.title': 'Frequently Asked Questions',
    'faq.description': 'Answers to the most common questions about our hosting. If you cannot find the answer to your question, please contact our support team.',
    
    // FAQ Questions
    'faq.how.to.start.question': 'How do I start using your hosting?',
    'faq.how.to.start.answer': `Getting started with our hosting is very simple:
      1. Register on the website
      2. Choose a suitable plan
      3. Pay using your preferred method
      4. Get access to the control panel
      5. Configure your server and start working
      
      If you have any questions, our support is available 24/7.`,
    
    'faq.virtualization.question': 'What virtualization do you use on servers?',
    'faq.virtualization.answer': 'We use KVM virtualization, which provides complete resource isolation and high performance. Each virtual server operates independently and has guaranteed CPU, memory, and disk space resources.',
    
    'faq.change.plan.question': 'Can I change my plan after purchase?',
    'faq.change.plan.answer': 'Yes, you can change your plan at any time. When upgrading, the cost is recalculated. All changes are made without data loss.',
    
    'faq.data.security.question': 'How is data security ensured?',
    'faq.data.security.answer': 'Data security is ensured through a complex of measures: using modern DDoS protection systems, 24/7 server monitoring, physical security of data centers, and compliance with all modern information security standards.',
    
    'faq.ddos.protection.question': 'What DDoS protection is provided?',
    'faq.ddos.protection.answer': 'We provide basic protection against DDoS attacks.',
    
    'faq.support.question': 'How is technical support provided?',
    'faq.support.answer': 'Technical support is available 24/7 through several channels: ticket system, online chat. Average response time is 15 minutes. Our specialists will help solve any issues related to server operation.',
    
    // FAQ Bottom Section
    'faq.no.answer.found': "Haven't found the answer to your question?",
    'faq.support.available': 'Our support team is ready to help 24/7. Average response time is 15 minutes.',
    'faq.contact.support': 'Contact Support',
    'faq.read.docs': 'Read Documentation',
    
    // Pricing Section
    'pricing.title': 'Plans and Pricing',
    'pricing.description': 'Choose the right plan for your project. All plans include a full set of features and 24/7 support.',
    'pricing.billing.monthly': 'Monthly',
    'pricing.billing.yearly': 'Yearly',
    'pricing.yearly.savings': 'Save up to 20% with annual billing',
    'pricing.popular.choice': 'Popular Choice',
    'pricing.active.clients': 'active clients',
    'pricing.month.short': 'mo',
    'pricing.year.short': 'yr',
    'pricing.month.yearly.short': 'mo',

    // Pricing Features
    'pricing.specs': 'Specifications',
    'pricing.included': 'Included',
    'pricing.features.traffic': 'Unlimited Traffic',
    'pricing.features.ddos': 'DDoS Protection Level 3, 4',
    'pricing.features.support': '24/7 Support',
    'pricing.features.backup': 'System Backup',
    'pricing.features.uptime': '99.9% Uptime',
    'pricing.features.monitoring': 'Monitoring',

    // Pricing Actions
    'pricing.select.plan': 'Select Plan',
    'pricing.custom.plan': 'Need a custom plan?',
    'pricing.contact.us': 'Contact Us',

    'locations.de-fra': 'Germany, Frankfurt am Main',

    'price': 'Price',
    'users': 'Users',
    
    // Testimonials Section
    'testimonials.title': 'Client Testimonials',
    
    // Statistics
    'testimonials.stats.happy.clients': 'Happy Clients',
    'testimonials.stats.average.rating': 'Average Rating',
    'testimonials.stats.countries': 'Countries',
    'testimonials.stats.reviews': 'Reviews',

    // Testimonials Data
    'testimonials.1.name': 'Alexander Petrov',
    'testimonials.1.position': 'CEO, TechStart',
    'testimonials.1.text': 'Switched to Nobuxe after several years of using another hosting. Server performance exceeded all expectations. Technical support responds instantly, and prices remain competitive.',
    
    'testimonials.2.name': 'Maria Ivanova',
    'testimonials.2.position': 'CTO, DataFlow',
    'testimonials.2.text': 'Reliability at the highest level. There hasn\'t been a single significant failure in a year of use. I would especially like to note the quality of DDoS protection – it works flawlessly.',
    
    'testimonials.3.name': 'Dmitry Sidorov',
    'testimonials.3.position': 'DevOps Engineer, CloudSys',
    'testimonials.3.text': 'The level of automation and convenience of the control panel is impressive. Scaling happens in a couple of clicks, and server performance allows you to handle any load.',    

    // Features Section
    'features.title': 'Our Advantages',
    'features.description': 'We provide reliable and high-performance solutions for your business, ensuring security and operational stability.',
    
    // Features Items
    'features.performance.title': 'High Performance',
    'features.performance.description': 'Modern equipment for high-speed operation',
    
    'features.reliability.title': 'Reliability',
    'features.reliability.description': '99.9% uptime',
    
    'features.flexibility.title': 'Flexibility',
    'features.flexibility.description': 'Complete resource scalability',
    
    'features.security.title': 'Security',
    'features.security.description': 'DDoS protection',
    
    // Features Call to Action
    'features.learn.more.question': 'Want to learn more about our capabilities?',
    'features.learn.more.button': 'More About Technologies',    

    // Hero Section
    'hero.title': 'Professional Hosting for Your Business',
    'hero.subtitle': 'Reliable Servers for Your Projects',
    'hero.description': 'We provide high-performance virtual and dedicated servers with DDoS protection and 24/7 support.',
    'hero.button.start': 'Get Started',
    
    // Stats
    'hero.stats.servers': 'Servers',
    'hero.stats.clients': 'Clients',
    'hero.stats.uptime': 'Uptime',
    'hero.stats.locations': 'Locations',
    
    // Server Status
    'hero.server.status.good': 'All Systems Operational',
    'hero.server.status.description': 'All systems are working as expected',
    
    // Trusted Section
    'hero.trusted.title': 'What does nobuxe use to ensure quality?',
    'hero.trusted.subtitle': 'Leading vendors and technology providers',    

    // Referral Program Page
    'referral.title': 'Referral Program',
    'referral.earning.title': 'Earn 5% from your referrals\' expenses, without limits.',
    
    // How it works
    'referral.how.it.works': 'How it works',
    'referral.share.link': 'Share your referral link',
    'referral.earn.percent': 'Earn 5% from any expenses of referred clients',
    'referral.payments': 'Payments to bank account or in cryptocurrency',
    
    // Partner Block
    'referral.partner.title': 'We are your reliable partner',
    'referral.partner.description': 'Our services work consistently and without failures.',
    
    // Rules
    'referral.rules.title': 'Program Rules',
    'referral.rules.no.spam': 'Spam and mass mailings are prohibited',
    'referral.rules.no.impersonation': 'It is forbidden to impersonate a Nobuxe representative',
    'referral.rules.payments.currency': 'Payments are made in local currency',
    'referral.rules.cancellation': 'Nobuxe reserves the right to cancel payments in case of rules violation',    

    // Showcase translations
    'showcase.title': 'Professional Hosting for Your Business',
    'showcase.description': 'Reliable servers with DDoS protection and 24/7 support',
    'showcase.emailPlaceholder': 'Enter your email',
    'showcase.ctaButton': 'Get Started',
    'showcase.loading': 'Loading...',
    'showcase.waitingForRecaptcha': 'Please wait...',
    'showcase.emailRequired': 'Email is required',
    'showcase.invalidEmail': 'Invalid email address',
    'showcase.recaptchaNotReady': 'Please wait for reCAPTCHA to load',
    'showcase.registrationError': 'Registration error. Please try again later',
    'showcase.registrationSuccess': 'Registration completed successfully!',
    'showcase.continueToAccount': 'Go to Dashboard',    
    
    'locations.de-fra.city.description': 'Frankfurt am Main is the largest city in the state of Hesse and the fifth largest city in Germany. Located in the heart of Europe, on the banks of the river Main, the city is famous for its historic center with its characteristic architecture and modern skyscrapers of the business district. With its central location and well-developed infrastructure, Frankfurt is the ideal low-latency server location for the whole of Europe.',
    'locations.de-fra.features.datacenter.title': 'Certified Data Center',
    'locations.de-fra.features.datacenter.description': 'The Frankfurt data center is ISO/IEC 27001 certified, ensuring the highest security standards',
    'locations.de-fra.features.network.title': 'Powerful Network Infrastructure',
    'locations.de-fra.features.network.description': 'High-speed network infrastructure with redundant connectivity and low latency',
    'locations.de-fra.features.security.title': 'Multi-Level Security',
    'locations.de-fra.features.security.description': '24/7 security, access control system, video surveillance and multi-factor authentication for personnel',
    'locations.de-fra.features.power.title': 'Reliable Power Supply',
    'locations.de-fra.features.power.description': 'Dual power system with backup diesel generators and UPS ensuring uninterrupted operation',
    'locations.de-fra.stats.rank': '5th',
    'locations.de-fra.stats.rank.description': 'largest city in Germany',
    'locations.de-fra.stats.latency': 'latency to major European cities',

    'about.title': "About Nobuxe",
    'about.subtitle': "Your reliable partner in virtual server hosting since 2023",
    'about.our.story': "Our Story",
    'about.story.content': "Nobuxe began with a vision to make premium VPS hosting accessible to everyone. Starting in 2023, we've grown from a small team of enthusiasts into a trusted hosting provider, serving clients worldwide with cutting-edge infrastructure and exceptional support.",
    'about.our.mission': "Our Mission",
    'about.mission.content': "We strive to provide enterprise-level hosting solutions that combine reliability, performance, and security at competitive prices. Our goal is to empower businesses and individuals with the tools they need to succeed in the digital world.",

    'about.feature1.title': "Advanced Security",
    'about.feature1.description': "State-of-the-art DDoS protection and security measures to keep your servers safe 24/7",
    'about.feature2.title': "24/7 Support",
    'about.feature2.description': "Professional technical support team ready to help you at any time",
    'about.feature3.title': "High Performance",
    'about.feature3.description': "Latest generation hardware and optimized infrastructure for maximum performance",

    'about.our.team': "Our Team",
    'about.team.member1.name': "Alex Smith",
    'about.team.member1.position': "CEO & Founder",
    'about.team.member2.name': "Maria Rodriguez",
    'about.team.member2.position': "Technical Director",
    'about.team.member3.name': "David Chen",
    'about.team.member3.position': "Support Team Lead",
    'about.team.member4.name': "Sophie Anderson",
    'about.team.member4.position': "Development Manager",    

    'contact.title': "Contact Us",
    'contact.subtitle': "Have questions? We're here to help and ready to respond to your inquiries",

    'contact.info.title': "Contact Information",
    'contact.hours': "Working Hours",
    'contact.hours.value': "24/7 Support Available",
    'contact.social': "Social Media",
    'contact.social.value': "Telegram, Discord",

    'contact.form.name': "Your Name",
    'contact.form.email': "Email Address",
    'contact.form.subject': "Subject",
    'contact.form.message': "Your Message",
    'contact.form.send': "Send Message",
    'contact.form.sending': "Sending...",
    'contact.form.success': "Message sent successfully! We'll get back to you soon.",
    'contact.form.error': "There was an error sending your message. Please try again.",   

    'contact.telegram.title': 'Telegram Support',
    'contact.telegram.description': 'Get instant support through our Telegram bot. We usually respond within minutes during business hours.',
    'contact.telegram.button': 'Open Telegram Chat',
    'contact.email.title': 'Email Support',
    'contact.email.description': 'Send us an email for detailed inquiries. Our support team will get back to you as soon as possible.',
    'contact.response.title': 'Fast Response Time',
    'contact.response.description': 'We aim to respond to all inquiries within 24 hours. For urgent matters, we recommend using Telegram for faster assistance.',
    'contact.additional.info': 'Our support team is available Monday through Friday, 9:00 AM - 6:00 PM UTC+3',

    'feed.title': 'Latest Updates',
    'feed.subtitle': 'Stay in touch with our latest news and announcements',
    'feed.error.loading': 'Failed to load posts',
    'feed.error.generic': 'Error loading posts: ',
    'feed.no.posts': 'No posts available',
    'feed.goto.channel': 'Open Telegram Channel',
    'feed.goto.post': 'View Post',
    'feed.image.alt': 'Post image',
    'feed.meta.title': 'Nobuxe News | Telegram Channel',
    'feed.meta.description': 'Stay updated with the latest news and updates from Nobuxe. Follow our Telegram channel for instant updates.',
    'locale': 'en-US',
    'news': 'News',
  }
};

const LanguageContext = createContext(null);

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    // Пытаемся получить сохраненный язык из localStorage
    const savedLanguage = localStorage.getItem('preferredLanguage');
    return savedLanguage || 'ru'; // По умолчанию русский
  });

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    localStorage.setItem('preferredLanguage', lang);
  };

  const t = (key) => {
    return translations[currentLanguage][key] || key;
  };

  const value = {
    currentLanguage,
    changeLanguage,
    t
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};