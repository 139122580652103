import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const DropdownItem = ({ 
  icon, 
  image, 
  title, 
  onClick, 
  isScrolled, 
  mobile, 
  href,
  isHighlighted,
  id
}) => {
  const Element = href ? motion.a : motion.button;
  const props = href ? { href } : { onClick };

  return (
    <Element
      {...props}
      id={id}
      role="menuitem"
      tabIndex={isHighlighted ? 0 : -1}
      className={`
        flex items-center space-x-3 px-4 py-2.5 w-full text-left whitespace-nowrap
        ${mobile ? 'text-gray-600 dark:text-gray-300' : 'text-gray-600 dark:text-gray-300'}
        ${isHighlighted ? 'bg-gray-100 dark:bg-gray-800' : ''}
        hover:text-blue-600 dark:hover:text-white
        hover:bg-gray-100 dark:hover:bg-gray-800
        transition-colors duration-200
        first:rounded-t-lg last:rounded-b-lg
        focus:outline-none focus:ring-2 focus:ring-blue-500
      `}
    >
      {image && (
        <div className="w-6 flex-shrink-0">
          <img src={image} alt="" className="w-full h-auto" />
        </div>
      )}
      {icon && <i className={`bx ${icon} text-xl flex-shrink-0`}></i>}
      <span className="text-sm font-medium truncate">{title}</span>
    </Element>
  );
};

const Dropdown = ({ 
  isOpen, 
  setIsOpen, 
  isScrolled, 
  mobile, 
  buttonText, 
  items,
  buttonIcon,
  id = 'dropdown',
  className,
  align = 'left' // добавляем проп для выравнивания
}) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  // Обновляем ширину при монтировании и изменении размера окна
  useEffect(() => {
    const updateWidth = () => {
      if (buttonRef.current) {
        const buttonWidth = buttonRef.current.offsetWidth;
        setDropdownWidth(buttonWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [buttonText]); // Обновляем при изменении текста кнопки

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setIsOpen]);

  // Handle keyboard navigation
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          setHighlightedIndex(prev => 
            prev < items.length - 1 ? prev + 1 : 0
          );
          break;
        case 'ArrowUp':
          event.preventDefault();
          setHighlightedIndex(prev => 
            prev > 0 ? prev - 1 : items.length - 1
          );
          break;
        case 'Enter':
          event.preventDefault();
          if (highlightedIndex >= 0) {
            const item = items[highlightedIndex];
            if (item.href) {
              window.location.href = item.href;
            } else if (item.onClick) {
              item.onClick();
            }
            setIsOpen(false);
            setHighlightedIndex(-1);
          }
          break;
        case 'Escape':
          event.preventDefault();
          setIsOpen(false);
          setHighlightedIndex(-1);
          buttonRef.current?.focus();
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, items, highlightedIndex, setIsOpen]);

  // Reset highlighted index when closing
  useEffect(() => {
    if (!isOpen) {
      setHighlightedIndex(-1);
    }
  }, [isOpen]);

  const buttonClasses = `
    flex items-center space-x-2 transition-colors duration-200 px-4 py-2 rounded-lg
    ${mobile ? 'w-full justify-between' : ''}
    ${isScrolled 
      ? 'text-gray-600 hover:text-blue-600 dark:text-gray-300 dark:hover:text-white' 
      : 'text-white/90 hover:text-white'
    }
    focus:outline-none focus:ring-2 focus:ring-blue-500
  `;

  const dropdownClasses = `
    absolute ${align === 'right' ? 'right-0' : 'left-0'} mt-1
    min-w-[${dropdownWidth}px]
    bg-white dark:bg-gray-900
    rounded-lg shadow-lg
    border border-gray-200 dark:border-gray-700
    divide-y divide-gray-100 dark:divide-gray-800
    z-50
    ${mobile ? 'relative w-full' : ''}
  `;

  return (
    <div className={`relative ${mobile ? 'w-full' : ''}`} ref={dropdownRef}>
      <button
        ref={buttonRef}
        className={buttonClasses}
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls={`${id}-menu`}
        id={`${id}-button`}
      >
        <span className="whitespace-nowrap">{buttonText}</span>
        {buttonIcon ? (
          <i className={`bx ${buttonIcon}`}></i>
        ) : (
          <i className={`bx ${isOpen ? 'bx-chevron-up' : 'bx-chevron-down'} ml-1`}></i>
        )}
      </button>
      
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={dropdownClasses}
            style={{ minWidth: dropdownWidth }}
            role="menu"
            aria-labelledby={`${id}-button`}
            id={`${id}-menu`}
          >
            {items.map((item, index) => (
              <DropdownItem 
                key={index}
                {...item}
                id={`${id}-item-${index}`}
                onClick={() => {
                  setIsOpen(false);
                  item.onClick?.();
                }}
                isScrolled={isScrolled}
                mobile={mobile}
                isHighlighted={index === highlightedIndex}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;