import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../../contexts/LanguageContext';

const TelegramFeed = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useLanguage();

  const CHANNEL_NAME = 'nobuxe_host';

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.allorigins.win/raw?url=${encodeURIComponent(
          `https://t.me/s/${CHANNEL_NAME}`
        )}`
      );

      if (!response.ok) {
        throw new Error(t('feed.error.loading'));
      }

      const html = await response.text();
      
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      
      const tgPosts = Array.from(doc.querySelectorAll('.tgme_widget_message'));
      
      const formattedPosts = tgPosts
        .map(post => {
          const id = post.getAttribute('data-post') || '';
          const dateElement = post.querySelector('.tgme_widget_message_date');
          const textElement = post.querySelector('.tgme_widget_message_text');
          const mediaElement = post.querySelector('.tgme_widget_message_photo_wrap');
          
          const date = dateElement?.querySelector('time')?.getAttribute('datetime') || '';
          const text = textElement?.innerHTML || '';
          const mediaStyle = mediaElement?.getAttribute('style') || '';
          
          const mediaUrl = mediaStyle.match(/background-image:url\('(.+?)'\)/)?.[1] || '';

          return {
            id,
            text,
            date: new Date(date).toLocaleString(t('locale')),
            media: mediaUrl,
          };
        })
        .filter(post => {
          const systemMessages = [
            'Channel created',
            'Канал создан',
            'изменил фото канала',
            'changed the channel photo',
            'изменил название канала',
            'changed the channel name',
            'изменил описание канала',
            'changed the channel description'
          ];
          
          return !systemMessages.some(msg => 
            post.text.toLowerCase().includes(msg.toLowerCase())
          );
        });

      setPosts(formattedPosts);
    } catch (err) {
      setError(t('feed.error.generic') + err.message);
      console.error('Error fetching posts:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
    const interval = setInterval(fetchPosts, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mt-8 relative w-full min-h-screen bg-gray-50 dark:bg-gradient-to-b dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
      <Helmet>
        <title>{t('feed.meta.title')}</title>
        <meta name="description" content={t('feed.meta.description')} />
        <meta property="og:title" content={t('feed.meta.title')} />
        <meta property="og:description" content={t('feed.meta.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {/* Background Elements */}
      <div className="absolute inset-0 w-full h-full overflow-hidden dark:block hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-30" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent" />
        
        <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
      </div>

      <div className="relative w-full max-w-[1200px] mx-auto px-4 py-20">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 dark:bg-clip-text dark:text-transparent dark:bg-gradient-to-r dark:from-white dark:via-blue-100 dark:to-blue-200 tracking-tight mb-6">
            {t('feed.title')}
          </h1>
          <p className="text-lg md:text-xl text-gray-600 dark:text-blue-100 mb-4">
            {t('feed.subtitle')}
          </p>
        </motion.div>

        {loading && !posts.length && (
          <div className="flex justify-center items-center p-8">
            <motion.div 
              className="w-12 h-12 border-4 border-blue-600 dark:border-blue-400 border-t-transparent rounded-full"
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            />
          </div>
        )}

        {error && (
          <motion.div 
            className="mx-auto max-w-lg"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <div className="bg-white/5 backdrop-blur-lg rounded-2xl p-6 border border-white/5">
              <div className="flex items-center mb-4 text-red-600 dark:text-red-400">
                <i className='bx bx-error-circle text-2xl mr-2'></i>
                {error}
              </div>
              <a 
                href={`https://t.me/${CHANNEL_NAME}`}
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-flex items-center bg-blue-600 hover:bg-blue-500 text-white font-medium py-4 px-8 rounded-xl transition-all duration-300 shadow-lg shadow-blue-500/20"
              >
                <i className='bx bxl-telegram text-xl mr-2'></i>
                {t('feed.goto.channel')}
              </a>
            </div>
          </motion.div>
        )}

        {!loading && !error && posts.length === 0 && (
          <motion.div 
            className="text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <div className="bg-white/5 backdrop-blur-lg rounded-2xl p-8 border border-white/5">
              <i className='bx bx-news text-6xl text-gray-600 dark:text-gray-400 mb-4'></i>
              <p className="mb-4 text-gray-600 dark:text-gray-400">{t('feed.no.posts')}</p>
              <a 
                href={`https://t.me/${CHANNEL_NAME}`}
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-flex items-center bg-blue-600 hover:bg-blue-500 text-white font-medium py-4 px-8 rounded-xl transition-all duration-300 shadow-lg shadow-blue-500/20"
              >
                <i className='bx bxl-telegram text-xl mr-2'></i>
                {t('feed.goto.channel')}
              </a>
            </div>
          </motion.div>
        )}

        <motion.div 
          className="space-y-6"
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
          initial="hidden"
          animate="show"
        >
          {posts.map(post => (
            <motion.div
              key={post.id}
              variants={{
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 }
              }}
              className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-6 dark:border dark:border-white/5 hover:bg-gray-50 dark:hover:bg-white/10 transition-all duration-300"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                  <i className='bx bx-calendar mr-2'></i>
                  {post.date}
                </div>
                <a
                  href={`https://t.me/${CHANNEL_NAME}/${post.id.split('/').pop()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-4 rounded-xl transition-all duration-300 shadow-lg shadow-blue-500/20 text-sm"
                >
                  <i className='bx bxl-telegram text-xl mr-2'></i>
                  {t('feed.goto.post')}
                </a>
              </div>
              <div 
                className="text-gray-900 dark:text-gray-100 space-y-4 prose dark:prose-invert max-w-none"
                dangerouslySetInnerHTML={{ 
                  __html: post.text 
                }}
              />
              {post.media && (
                <motion.div 
                  className="mt-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <img
                    src={post.media}
                    alt={t('feed.image.alt')}
                    className="rounded-xl w-full shadow-lg"
                    loading="lazy"
                  />
                </motion.div>
              )}
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default TelegramFeed;