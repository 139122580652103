import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import Button from '../ui/Button';

const Features = () => {
  const { t } = useLanguage();

  const features = [
    {
      icon: 'bxs-bolt',
      title: t('features.performance.title'),
      description: t('features.performance.description'),
    },
    {
      icon: 'bxs-server',
      title: t('features.reliability.title'),
      description: t('features.reliability.description'),
    },
    {
      icon: 'bxs-cog',
      title: t('features.flexibility.title'),
      description: t('features.flexibility.description'),
    },
    {
      icon: 'bxs-shield-alt-2',
      title: t('features.security.title'),
      description: t('features.security.description'),
    }
  ];

  return (
    <div className="relative w-full bg-white dark:bg-transparent pt-32">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-10 dark:opacity-30" />
        <div className="absolute inset-0 bg-gradient-to-b from-white via-gray-50 to-white dark:from-transparent dark:via-blue-950 dark:to-gray-900" />
        
        {/* Gradient orbs */}
        <div className="absolute top-60 left-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        <div className="absolute top-80 right-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
      </div>

      <div className="relative w-full max-w-[1200px] mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center max-w-3xl mx-auto mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">
            {t('features.title')}
          </h2>
          <p className="text-gray-600 dark:text-gray-400 text-lg">
            {t('features.description')}
          </p>
        </motion.div>

        <AnimatePresence mode="sync">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                whileHover={{ y: -8, transition: { duration: 0.3 } }}
                className="group relative h-full"
              >
                <div className="relative z-10 flex flex-col items-center text-center p-8 rounded-2xl bg-gradient-to-b from-gray-50 to-white dark:from-blue-950/50 dark:to-blue-900/30 backdrop-blur-lg border border-gray-200/50 dark:border-white/10 shadow-xl h-full">
                  {/* Glow effect */}
                  <div className="absolute inset-0 rounded-2xl bg-blue-500/[0.02] group-hover:bg-blue-500/[0.04] dark:bg-blue-500/5 dark:group-hover:bg-blue-500/10 transition-colors duration-300" />
                  
                  {/* Icon container with glow */}
                  <div className="relative mb-6">
                    <div className="absolute inset-0 bg-blue-500/10 dark:bg-blue-500/20 rounded-full blur-xl group-hover:blur-2xl transition-all duration-300" />
                    <div className="relative w-16 h-16 rounded-full bg-gradient-to-br from-blue-400 to-blue-500 dark:from-blue-500 dark:to-blue-600 flex items-center justify-center shadow-lg group-hover:shadow-blue-500/25 transition-shadow duration-300">
                      <i className={`bx ${feature.icon} text-3xl text-white`} />
                    </div>
                  </div>

                  {/* Text content */}
                  <div className="flex flex-col flex-grow">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-3 group-hover:text-blue-600 dark:group-hover:text-blue-300 transition-colors duration-300">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 leading-relaxed flex-grow">
                      {feature.description}
                    </p>
                  </div>

                  {/* Decorative corners */}
                  <div className="absolute -top-2 -left-2 w-6 h-6 border-t-2 border-l-2 border-blue-400/30 dark:border-blue-500/30 rounded-tl-lg" />
                  <div className="absolute -bottom-2 -right-2 w-6 h-6 border-b-2 border-r-2 border-blue-400/30 dark:border-blue-500/30 rounded-br-lg" />
                </div>
              </motion.div>
            ))}
          </div>
        </AnimatePresence>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="text-center mt-16"
        >
          <p className="text-gray-600 dark:text-gray-400 mb-6 text-lg">
            {t('features.learn.more.question')}
          </p>
          <Button 
            variant="primary" 
            size="lg"
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 px-6 rounded-xl"
          >
            {t('features.learn.more.button')}
          </Button>
        </motion.div>
      </div>
    </div>
  );
};

export default Features;