import React, { useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { motion } from 'framer-motion';

const ContactPage = () => {
  const { t } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative w-full min-h-screen bg-gray-50 dark:bg-gradient-to-b dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
      {/* Background Elements - только для темной темы */}
      <div className="absolute inset-0 w-full h-full overflow-hidden dark:block hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-30" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent" />
        
        <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
        <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10" />
      </div>

      <div className="relative w-full max-w-[1200px] mx-auto px-4 py-20">
        {/* Hero Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h1 className="mt-8 text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 dark:bg-clip-text dark:text-transparent dark:bg-gradient-to-r dark:from-white dark:via-blue-100 dark:to-blue-200 tracking-tight mb-6">
            {t('contact.title')}
          </h1>
          <p className="text-lg md:text-xl text-gray-600 dark:text-blue-100 mb-4">
            {t('contact.subtitle')}
          </p>
        </motion.div>

        {/* Contact Methods */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto grid md:grid-cols-2 gap-8"
        >
          {/* Telegram Support */}
          <div className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-8 dark:border dark:border-white/5">
            <div className="text-center space-y-6">
              <div className="flex justify-center mb-6">
                <i className="bx bxl-telegram text-6xl text-blue-600 dark:text-blue-400"></i>
              </div>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                {t('contact.telegram.title')}
              </h2>
              <p className="text-gray-600 dark:text-blue-100 mb-8">
                {t('contact.telegram.description')}
              </p>
              <a
                href="https://t.me/nobuxe_supbot"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-500 text-white font-medium py-4 px-8 rounded-xl transition-all duration-300 shadow-lg shadow-blue-500/20"
              >
                <i className="bx bxl-telegram text-xl mr-2"></i>
                {t('contact.telegram.button')}
              </a>
            </div>
          </div>

          {/* Email Support */}
          <div className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-8 dark:border dark:border-white/5">
            <div className="text-center space-y-6">
              <div className="flex justify-center mb-6">
                <i className="bx bx-envelope text-6xl text-blue-600 dark:text-blue-400"></i>
              </div>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                {t('contact.email.title')}
              </h2>
              <p className="text-gray-600 dark:text-blue-100 mb-8">
                {t('contact.email.description')}
              </p>
              <a
                href="mailto:support@nobuxe.com"
                className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-500 text-white font-medium py-4 px-8 rounded-xl transition-all duration-300 shadow-lg shadow-blue-500/20"
              >
                <i className="bx bx-envelope text-xl mr-2"></i>
                support@nobuxe.com
              </a>
            </div>
          </div>
        </motion.div>

        {/* Response Time Info */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="mt-12 text-center"
        >
          <div className="bg-white dark:bg-white/5 shadow-lg dark:backdrop-blur-lg rounded-2xl p-6 dark:border dark:border-white/5 max-w-2xl mx-auto">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              {t('contact.response.title')}
            </h3>
            <p className="text-gray-600 dark:text-blue-100">
              {t('contact.response.description')}
            </p>
          </div>
        </motion.div>

        {/* Additional Info */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="mt-8 text-center"
        >
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {t('contact.additional.info')}
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactPage;