import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../../contexts/LanguageContext';
import { motion } from 'framer-motion';

export const ReferralProgram = () => {
  const { t } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut'
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Реферальная программа | Nobuxe - Заработайте на рекомендациях</title>
        <meta 
          name="description" 
          content="Присоединяйтесь к реферальной программе Nobuxe. Получайте вознаграждение за каждого приведенного клиента на условиях партнерской программы." 
        />
        <meta 
          name="keywords" 
          content="реферальная программа, партнерская программа, заработок, vps хостинг, рекомендации" 
        />
        {/* Open Graph */}
        <meta 
          property="og:title" 
          content="Реферальная программа Nobuxe - Зарабатывайте с нами" 
        />
        <meta 
          property="og:description" 
          content="Станьте партнером Nobuxe и получайте вознаграждение за рекомендации наших VPS серверов." 
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nobuxe.com/referral-program" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta 
          name="twitter:title" 
          content="Реферальная программа Nobuxe" 
        />
        <meta 
          name="twitter:description" 
          content="Станьте партнером Nobuxe и получайте вознаграждение за рекомендации." 
        />
      </Helmet>

      <section className="relative w-full min-h-screen bg-gradient-to-b from-gray-50 via-white to-gray-50 dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
        {/* Background effects */}
        <div className="absolute inset-0 w-full h-full overflow-hidden">
          <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-5 dark:opacity-30" />
          
          {/* Gradient orbs */}
          <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
          <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
          <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        </div>

        <div className="relative container mx-auto px-4 py-24">
          {/* Заголовок */}
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-blue-800 to-blue-900 dark:from-white dark:via-blue-100 dark:to-blue-200"
          >
            {t('referral.title')}
          </motion.h1>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Основной блок */}
          <motion.div 
            variants={itemVariants}
            className="bg-white dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 mb-8 shadow-xl dark:shadow-none"
          >
            {/* Заголовок с процентами */}
            <h2 className="text-2xl text-blue-500 dark:text-blue-400 mb-8">
              {t('referral.earning.title')}
            </h2>

            {/* Как это работает */}
            <div className="mb-8">
              <h3 className="text-xl mb-4 font-medium text-gray-900 dark:text-white">
                {t('referral.how.it.works')}
              </h3>
              <div className="space-y-4">
                <motion.div variants={itemVariants} className="flex items-center gap-3">
                  <i className="bx bx-link text-blue-500 dark:text-blue-400 text-2xl"></i>
                  <span className="text-gray-700 dark:text-gray-300">{t('referral.share.link')}</span>
                </motion.div>
                <motion.div variants={itemVariants} className="flex items-center gap-3">
                  <i className="bx bx-dollar-circle text-blue-500 dark:text-blue-400 text-2xl"></i>
                  <span className="text-gray-700 dark:text-gray-300">{t('referral.earn.percent')}</span>
                </motion.div>
                <motion.div variants={itemVariants} className="flex items-center gap-3">
                  <i className="bx bx-wallet text-blue-500 dark:text-blue-400 text-2xl"></i>
                  <span className="text-gray-700 dark:text-gray-300">{t('referral.payments')}</span>
                </motion.div>
              </div>
            </div>

            {/* Блок "Мы — ваш надежный партнер" */}
            <motion.div 
              variants={itemVariants}
              className="bg-gray-50/50 dark:bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-gray-200/50 dark:border-white/5"
            >
              <h3 className="text-xl mb-2 font-medium text-gray-900 dark:text-white">
                {t('referral.partner.title')}
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                {t('referral.partner.description')}
              </p>
            </motion.div>
          </motion.div>

          {/* Правила программы */}
          <motion.div 
            variants={itemVariants}
            className="bg-white dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 shadow-xl dark:shadow-none"
          >
            <h3 className="text-xl mb-6 font-medium text-gray-900 dark:text-white">
              {t('referral.rules.title')}
            </h3>
            <div className="space-y-4">
              <motion.div variants={itemVariants} className="flex items-center gap-3">
                <i className="bx bx-x-circle text-blue-500 dark:text-blue-400 text-2xl"></i>
                <span className="text-gray-700 dark:text-gray-300">{t('referral.rules.no.spam')}</span>
              </motion.div>
              <motion.div variants={itemVariants} className="flex items-center gap-3">
                <i className="bx bx-user-x text-blue-500 dark:text-blue-400 text-2xl"></i>
                <span className="text-gray-700 dark:text-gray-300">{t('referral.rules.no.impersonation')}</span>
              </motion.div>
              <motion.div variants={itemVariants} className="flex items-center gap-3">
                <i className="bx bx-dollar text-blue-500 dark:text-blue-400 text-2xl"></i>
                <span className="text-gray-700 dark:text-gray-300">{t('referral.rules.payments.currency')}</span>
              </motion.div>
              <motion.div variants={itemVariants} className="flex items-center gap-3">
                <i className="bx bx-shield text-blue-500 dark:text-blue-400 text-2xl"></i>
                <span className="text-gray-700 dark:text-gray-300">{t('referral.rules.cancellation')}</span>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
    </>
  );
};

export default ReferralProgram;