import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';

const Testimonials = () => {
  const { t } = useLanguage();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const testimonials = [
    {
      id: 1,
      name: t('testimonials.1.name'),
      position: t('testimonials.1.position'),
      image: 'https://i.ibb.co/s3PDzV3/download-1.jpg',
      rating: 5,
      text: t('testimonials.1.text'),
      company: {
        logo: 'https://gamesensesports.com/wp-content/uploads/2020/05/full-color-techstars-logo.png',
        name: 'TechStars'
      }
    },
    {
      id: 2,
      name: t('testimonials.2.name'),
      position: t('testimonials.2.position'),
      image: 'https://i.ibb.co/0ygnpDv/download.jpg',
      rating: 5,
      text: t('testimonials.2.text'),
      company: {
        logo: 'https://pbs.twimg.com/media/FyAlk6OWIAIRQJ5.png',
        name: 'DataFlow'
      }
    },
    {
      id: 3,
      name: t('testimonials.3.name'),
      position: t('testimonials.3.position'),
      image: 'https://i.ibb.co/XWF16dy/download.jpg',
      rating: 5,
      text: t('testimonials.3.text'),
      company: {
        logo: 'https://cloudsysnetwork.com/wp-content/uploads/2021/03/Cloudsys-1.png',
        name: 'CloudSys'
      }
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isAnimating) {
        paginate(1);
      }
    }, 5000);
    return () => clearInterval(timer);
  }, [isAnimating]);

  const paginate = (newDirection) => {
    if (isAnimating) return;
    setDirection(newDirection);
    setCurrentSlide((prev) => (prev + newDirection + testimonials.length) % testimonials.length);
  };

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 100 : -100,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 100 : -100,
      opacity: 0
    })
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const stats = [
    { label: t('testimonials.stats.happy.clients'), value: '5000+' },
    { label: t('testimonials.stats.average.rating'), value: '4.9/5' },
    { label: t('testimonials.stats.countries'), value: '25+' },
    { label: t('testimonials.stats.reviews'), value: '1200+' }
  ];

  return (
    <section className="relative w-full bg-gradient-to-b from-gray-50 via-white to-gray-50 dark:from-gray-900 dark:via-blue-950 dark:to-gray-900">
      {/* Background effects с фиксированной высотой */}
      <div className="absolute inset-0 w-full overflow-hidden pointer-events-none">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center opacity-5 dark:opacity-30" />
        
        <div className="absolute top-20 left-[10%] w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        <div className="absolute top-10 right-[10%] w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
        <div className="absolute top-60 left-[15%] w-64 h-64 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-5 dark:opacity-10" />
      </div>

      <div className="relative container mx-auto px-4 py-24">
        {/* Заголовок */}
        <div className="text-center mb-10">
          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-blue-800 to-blue-900 dark:from-white dark:via-blue-100 dark:to-blue-200">
            {t('testimonials.title')}
          </h2>
        </div>

        {/* Слайдер с фиксированной высотой */}
        <div className="relative px-4 max-w-4xl mx-auto">
          {/* Контейнер для слайдера с фиксированной высотой */}
          <div className="h-[600px] sm:h-[500px] relative overflow-hidden">
            {/* Кнопки навигации */}
            <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 flex justify-between z-20 px-4">
              <button
                onClick={() => !isAnimating && paginate(-1)}
                className="w-8 h-8 rounded-full bg-white/80 dark:bg-white/5 backdrop-blur-lg flex items-center justify-center text-gray-900 dark:text-white hover:bg-white dark:hover:bg-white/10 transition-colors"
                disabled={isAnimating}
              >
                <i className="bx bx-chevron-left text-xl"></i>
              </button>
              <button
                onClick={() => !isAnimating && paginate(1)}
                className="w-8 h-8 rounded-full bg-white/80 dark:bg-white/5 backdrop-blur-lg flex items-center justify-center text-gray-900 dark:text-white hover:bg-white dark:hover:bg-white/10 transition-colors"
                disabled={isAnimating}
              >
                <i className="bx bx-chevron-right text-xl"></i>
              </button>
            </div>

            {/* Слайдер контент */}
            <div className="absolute inset-0 flex items-center justify-center">
              <AnimatePresence initial={false} custom={direction} mode="popLayout">
                <motion.div
                  key={currentSlide}
                  custom={direction}
                  variants={slideVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 }
                  }}
                  drag="x"
                  dragConstraints={{ left: 0, right: 0 }}
                  dragElastic={1}
                  onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x);
                    if (swipe < -swipeConfidenceThreshold) {
                      paginate(1);
                    } else if (swipe > swipeConfidenceThreshold) {
                      paginate(-1);
                    }
                  }}
                  onAnimationStart={() => setIsAnimating(true)}
                  onAnimationComplete={() => setIsAnimating(false)}
                  className="w-full px-4 cursor-grab active:cursor-grabbing"
                >
                  <div className="bg-white dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-8 shadow-xl dark:shadow-none transform-gpu">
                    <div className="flex flex-col items-center">
                      {/* Аватар */}
                      <div className="mb-4">
                        <div className="w-24 h-24 rounded-full overflow-hidden ring-4 ring-gray-100 dark:ring-white/10">
                          <img
                            src={testimonials[currentSlide].image}
                            alt={testimonials[currentSlide].name}
                            className="w-full h-full object-cover"
                            loading="lazy"
                          />
                        </div>
                      </div>

                      {/* Информация */}
                      <h3 className="text-xl font-bold text-center mb-2 text-gray-900 dark:text-white">
                        {testimonials[currentSlide].name}
                      </h3>
                      <p className="text-blue-500 dark:text-blue-400 mb-4">
                        {testimonials[currentSlide].position}
                      </p>

                      {/* Рейтинг */}
                      <div className="flex gap-1 mb-6">
                        {[...Array(testimonials[currentSlide].rating)].map((_, i) => (
                          <i key={i} className="bx bxs-star text-yellow-400"></i>
                        ))}
                      </div>

                      {/* Компания */}
                      <div className="flex items-center mb-6">
                        <img
                          src={testimonials[currentSlide].company.logo}
                          alt={testimonials[currentSlide].company.name}
                          className="h-6 mr-2 dark:invert"
                          loading="lazy"
                        />
                        <span className="text-gray-500 dark:text-gray-400">
                          {testimonials[currentSlide].company.name}
                        </span>
                      </div>

                      {/* Текст отзыва */}
                      <blockquote className="text-center text-gray-600 dark:text-gray-300">
                        "{testimonials[currentSlide].text}"
                      </blockquote>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>

          {/* Индикаторы */}
          <div className="flex justify-center gap-2 mt-6">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  if (!isAnimating) {
                    setDirection(index > currentSlide ? 1 : -1);
                    setCurrentSlide(index);
                  }
                }}
                disabled={isAnimating}
                className={`h-1.5 rounded-full transition-all duration-300 ${
                  currentSlide === index 
                    ? 'w-6 bg-blue-500 dark:bg-blue-400' 
                    : 'w-1.5 bg-gray-300 dark:bg-gray-600'
                }`}
              />
            ))}
          </div>

          {/* Статистика */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-16">
            {stats.map((stat) => (
              <div 
                key={stat.label} 
                className="bg-white dark:bg-white/5 backdrop-blur-lg border border-gray-200 dark:border-white/10 rounded-xl p-6 text-center"
              >
                <div className="text-2xl font-bold mb-2 text-gray-900 dark:text-white">
                  {stat.value}
                </div>
                <div className="text-gray-500 dark:text-gray-400 text-sm">
                  {stat.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;